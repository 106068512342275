import React from "react";
import { Switch, Redirect } from "react-router-dom";

import RouteWithLayout from "./RouteWithLayout";
import DefaultLayout from "../layouts/DefaultLayout";
import NotFound from "../layouts/404";

import { Route } from "react-router-dom";

import Dashboard from "../views/Dashboard";
import Login from "../views/Login";
import Profile from "../views/Profile";
import ChangePassConfirm from "../views/Profile/ChangePassConfirm";
import Donations from "../views/Donations";
import Projects from "../views/Projects";
import Contact from "../views/Contact";
import ForgotPassword from "../views/ForgotPassword";
import TicketDetails from "../views/Contact/TicketDetails";
import ProjectDetails from "../views/Projects/ProjectDetails";
// import Contact from "../views/Conact";

const Routes = () => {
  return (
    <Switch>
      <RouteWithLayout
        path="/"
        exact
        component={Dashboard}
        layout={DefaultLayout}
      />

      <RouteWithLayout
        path="/profile"
        exact
        component={Profile}
        layout={DefaultLayout}
      />
      <RouteWithLayout
        path="/change-pass-confirm"
        exact
        component={ChangePassConfirm}
        layout={DefaultLayout}
      />

      <RouteWithLayout
        path="/donations"
        exact
        component={Donations}
        layout={DefaultLayout}
      />

      <RouteWithLayout
        path="/projects"
        exact
        component={Projects}
        layout={DefaultLayout}
      />
      <RouteWithLayout
        path="/projects/:id"
        exact
        component={ProjectDetails}
        layout={DefaultLayout}
      />

      <RouteWithLayout
        path="/contact"
        exact
        component={Contact}
        layout={DefaultLayout}
      />

      <RouteWithLayout
        path="/ticket/:id"
        exact
        component={TicketDetails}
        layout={DefaultLayout}
      />

      <Route component={NotFound} exact path="/not-found" />
      <Route component={Login} exact path="/login" />
      <Route component={ForgotPassword} exact path="/forgot-password" />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
