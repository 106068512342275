import React from "react";
import "../main.css";

const ImagePreview = ({ link, title, prevCallback }) => {
  const handleClose = () => {
    prevCallback();
  };
  return (
    <div className="image__preview__container">
      <div className="image__close__container" onClick={handleClose}></div>
      <div className="image__preview__donwload__close__container">
        {/* <a href={link} download={title} target="_blank" rel="noreferrer">
          <i className="fa fa-download" aria-hidden="true"></i>
        </a> */}
        <span onClick={handleClose}>&#10005;</span>
      </div>
      <img
        className="image__preview__image__to__preview"
        src={link}
        alt={title}
      />
    </div>
  );
};

export default ImagePreview;
