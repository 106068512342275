const FormatDate = ({ date, format = null, widthTime = false }) => {
  if (!date) {
    return "../../...";
  }
  var sBrowser,
    sUsrAg = navigator.userAgent;
  if (sUsrAg.indexOf("Safari") > -1) {
    sBrowser = "Safari";
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
  }

  if (sBrowser === "Safari") {
    date = date.replace(" ", "T");
  }

  // const date1 = date.replace(/-/g, "/");
  let dateToFormat = new Date(date);
  let parts = date.split("-");
  if (parts[2].length === 4)
    dateToFormat = new Date(parts[2] + "-" + parts[1] + "-" + parts[0]);

  let dd = dateToFormat.getDate();
  let mm = dateToFormat.getMonth() + 1;

  let mmLetter = dateToFormat.toLocaleString("default", { month: "short" });

  let yyyy = dateToFormat.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }

  let hh = dateToFormat.getHours();
  let mi = dateToFormat.getMinutes();

  if (mi < 10) {
    mi = "0" + mi;
  }

  if (widthTime) {
    if (format === "letter") {
      return dd + " " + mmLetter + " " + yyyy + ", " + hh + ":" + mi;
    } else {
      return dd + "/" + mm + "/" + yyyy + ", " + hh + ":" + mi;
    }
  } else {
    if (format === "letter") {
      return dd + " " + mmLetter + " " + yyyy;
    } else {
      return dd + "/" + mm + "/" + yyyy;
    }
  }
};

export default FormatDate;
