import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import API from "../../../utils/API";
import Loading from "../../../components/Loading";
import FormatDate from "../../../components/FormatDate";
import Popup from "../../../components/Popup";
// import emptyImage from "../../../assets/images/emptyImage.png";
import emptyImage from "../../../assets/images/projects-empty-image.png";

import donEffectueIcon from "../../../assets/images/projects/doneffectue.png";
import donEffectueSelectedIcon from "../../../assets/images/projects/doneffectueSelected.png";
import donEffectueActualIcon from "../../../assets/images/projects/doneffectueActual.png";

import diagnostiqueIcon from "../../../assets/images/projects/diagnostique.png";
import diagnostiqueSelectedIcon from "../../../assets/images/projects/diagnostiqueSelected.png";
import diagnostiqueActualIcon from "../../../assets/images/projects/diagnostiqueActual.png";

import affectationVillageIcon from "../../../assets/images/projects/affectationvillage.png";
import affectationVillageSelectedIcon from "../../../assets/images/projects/affectationvillageSelected.png";
import affectationVillageActualIcon from "../../../assets/images/projects/affectationvillageActual.png";

import debutTravauxIcon from "../../../assets/images/projects/debuttravaux.png";
import debutTravauxSelectedIcon from "../../../assets/images/projects/debuttravauxSelected.png";
import debutTravauxActualIcon from "../../../assets/images/projects/debuttravauxActual.png";

import constructionEnCoursIcon from "../../../assets/images/projects/constructionencours.png";
import constructionEnCoursSelectedIcon from "../../../assets/images/projects/constructionencoursSelected.png";
import constructionEnCoursActualIcon from "../../../assets/images/projects/constructionencoursActual.png";

import puitsTermineIcon from "../../../assets/images/projects/puitstermine.png";
import puitsTermineSelectedIcon from "../../../assets/images/projects/puitstermineSelected.png";
import puitsTermineActualIcon from "../../../assets/images/projects/puitstermineActual.png";
import ImagePreview from "../ImagePreview";

const ProjectDetails = () => {
  const { id } = useParams();

  const [project, setProject] = useState({});
  const [loading, setLoading] = useState(false);
  const [projectImage, setProjectImage] = useState({
    imageLink: "",
    imageTitle: "",
  });

  const [ImagePreviewOpen, setImagePreviewOpen] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/my-project/${id}`
        );

        if (res.code === 200) {
          setProject(res.data);
          setLoading(false);
        } else {
          alert("Une erreur est survenue, contactez l'équipe dev");
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getData();

    return () => {
      setProject({});
    };
  }, [id]);

  const stepsActive = {
    donationSet: project.projectStep === "donationSet",
    Diagnostic: project.projectStep === "Diagnostic",
    villageAssignment: project.projectStep === "villageAssignment",
    startWorks: project.projectStep === "startWorks",
    constructionProgress: project.projectStep === "constructionProgress",
    wellFinished: project.projectStep === "wellFinished",
  };

  const handleImageClick = (e) => {
    setProjectImage({
      imageLink: e.target.src,
      imageTitle: e.target.alt,
    });
    setImagePreviewOpen(true);
  };
  const prevCallback = () => {
    setImagePreviewOpen(false);
  };
  const setPopupVisible = () => {
    setPopupOpen(true);
  };
  const popupCallback = () => {
    setPopupOpen(false);
  };
  // console.log("project?", project.town)
  return loading ? (
    <Loading />
  ) : (
    <div>
      {ImagePreviewOpen ? (
        <ImagePreview
          link={projectImage.imageLink}
          title={projectImage.imageTitle}
          prevCallback={prevCallback}
        />
      ) : (
        ""
      )}

      <div className="project_details__container paper">
        <div className="project_details__title_status">
          <h2 className="project_details__title page__title">{project.name}</h2>
          <span
            className={
              project.projectStatus === "ended"
                ? "project__info__status__badge project__ended__bg"
                : "project__info__status__badge"
            }
          >
            {project.projectStatus === "inprogress"
              ? "EN COURS"
              : project.projectStatus === "ended"
              ? "TERMINÉ"
              : project.projectStatus}
          </span>
        </div>
        <div className="project_details__image__container" align="center">
          {project.photo ? (
            <img
              className="project_details__image"
              src={`${process.env.REACT_APP_BASE_IMAGE_URL}/${project.photo.imageName}`}
              alt={project.name}
            />
          ) : (
            <img
              className="project_details__image"
              src={emptyImage}
              alt={project.name}
            />
          )}
        </div>
        <div align="center" className="project_details__desc_container">
          {/* {project.description} */}
          <div
            dangerouslySetInnerHTML={{
              __html: project.description,
            }}
          ></div>
        </div>
        <div className="project_details__progress__container">
          <div className="project__status_line__cont">
            <div className="project__status_line col-md-12">
              <span
                className="project__state__icon"
                style={{ marginLeft: "calc(" + 100 / 7 + "% - 25px)" }}
              >
                <img
                  src={
                    stepsActive.donationSet &&
                    project.projectStep === "donationSet"
                      ? donEffectueActualIcon
                      : stepsActive.donationSet
                      ? donEffectueSelectedIcon
                      : donEffectueIcon
                  }
                  alt="Don éffectué"
                  className={
                    stepsActive.donationSet &&
                    project.projectStep !== "donationSet"
                      ? "actualstep__dropShadow"
                      : ""
                  }
                />
                <br />
                <b className="project__step__title">Don éffectué</b>
              </span>

              <span
                className="project__state__icon"
                style={{ marginLeft: "calc(" + (100 / 7) * 2 + "% - 25px)" }}
              >
                <img
                  src={
                    stepsActive.Diagnostic &&
                    project.projectStep === "Diagnostic"
                      ? diagnostiqueActualIcon
                      : stepsActive.Diagnostic
                      ? diagnostiqueSelectedIcon
                      : diagnostiqueIcon
                  }
                  alt="Diagnostic"
                  className={
                    stepsActive.Diagnostic &&
                    project.projectStep !== "Diagnostic"
                      ? "actualstep__dropShadow"
                      : ""
                  }
                />
                <br />
                <b className="project__step__title">Diagnostic</b>
              </span>

              <span
                className="project__state__icon"
                style={{ marginLeft: "calc(" + (100 / 7) * 3 + "% - 25px)" }}
              >
                <img
                  src={
                    stepsActive.villageAssignment &&
                    project.projectStep === "villageAssignment"
                      ? affectationVillageActualIcon
                      : stepsActive.villageAssignment
                      ? affectationVillageSelectedIcon
                      : affectationVillageIcon
                  }
                  alt="Affectation Village"
                  className={
                    stepsActive.villageAssignment &&
                    project.projectStep !== "villageAssignment"
                      ? "actualstep__dropShadow"
                      : ""
                  }
                />
                <br />
                <b className="project__step__title">Affectation Village</b>
              </span>

              <span
                className="project__state__icon"
                style={{ marginLeft: "calc(" + (100 / 7) * 4 + "% - 25px)" }}
              >
                <img
                  src={
                    stepsActive.startWorks &&
                    project.projectStep === "startWorks"
                      ? debutTravauxActualIcon
                      : stepsActive.startWorks
                      ? debutTravauxSelectedIcon
                      : debutTravauxIcon
                  }
                  alt="Début Travaux"
                  className={
                    stepsActive.startWorks &&
                    project.projectStep !== "startWorks"
                      ? "actualstep__dropShadow"
                      : ""
                  }
                />
                <br />
                <b className="project__step__title">Début Travaux</b>
              </span>

              <span
                className="project__state__icon"
                style={{ marginLeft: "calc(" + (100 / 7) * 5 + "% - 25px)" }}
              >
                <img
                  src={
                    stepsActive.constructionProgress &&
                    project.projectStep === "constructionProgress"
                      ? constructionEnCoursActualIcon
                      : stepsActive.constructionProgress
                      ? constructionEnCoursSelectedIcon
                      : constructionEnCoursIcon
                  }
                  alt="Construction En Cours"
                  className={
                    stepsActive.constructionProgress &&
                    project.projectStep !== "constructionProgress"
                      ? "actualstep__dropShadow"
                      : ""
                  }
                />
                <br />
                <b className="project__step__title">Construction En Cours</b>
              </span>

              <span
                className="project__state__icon"
                style={{ marginLeft: "calc(" + (100 / 7) * 6 + "% - 25px)" }}
              >
                <img
                  src={
                    stepsActive.wellFinished &&
                    project.projectStep === "wellFinished"
                      ? puitsTermineActualIcon
                      : stepsActive.wellFinished
                      ? puitsTermineSelectedIcon
                      : puitsTermineIcon
                  }
                  alt="Puits Terminé"
                  className={
                    stepsActive.wellFinished &&
                    project.projectStep !== "wellFinished"
                      ? "actualstep__dropShadow"
                      : ""
                  }
                />
                <br />
                <b className="project__step__title">Puits Terminé</b>
              </span>
            </div>
          </div>
        </div>
        <div>
          {popupOpen ? (
            <Popup popupCallback={popupCallback}>
              <iframe
                width="100%"
                height="100%"
                style={{ borderRadius: "10px" }}
                frameBorder="0"
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyA_JoLLqRPfEiSvu5nbAHy-dS8r5w-ODY4&q=${project.locationLat},${project.locationLong}`}
                title="map"
              ></iframe>
            </Popup>
          ) : (
            ""
          )}
        </div>
        <div className="project_details__infos_container">
          <div>
            <ul>
              <li>
                <b>Type de projet : </b> {project.type}
              </li>
              <li>
                <b>Type de financement : </b> {project.financementType}
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li>
                <b>Nom du puits (plaque) : </b>
                {project.plateProjectName ? project.plateProjectName : ""}
              </li>
              <li>
                <b>Nom du donateur (plaque) : </b>
                {project.plateDonorName ? project.plateDonorName : ""}
              </li>
            </ul>
          </div>
        </div>
        <div className="project__status_line_ended col-md-12 row">
          <div className="col-6 col-sm-6 col-md-3 project___status__ended_details_cont">
            <strong>Date d'inauguration</strong>
            <br />
            <b>
              {project.endDate ? <FormatDate date={project.endDate} /> : "."}
            </b>
          </div>
          <div className="col-6 col-sm-6 col-md-3 project___status__ended_details_cont">
            <strong>Beneficiaires</strong>
            <br />
            <b>
              {project.beneficiairesNumber ? project.beneficiairesNumber : ""}
            </b>
          </div>
          <div className="col-6 col-sm-6 col-md-3 project___status__ended_details_cont">
            <strong>Profondeur</strong>
            <br />
            <b>{project.projectDepth ? project.projectDepth : "."}</b>
          </div>
          <div className="col-6 col-sm-6 col-md-3 project___status__ended_details_cont">
            <strong>Localisation</strong>
            <br />
            <b onClick={setPopupVisible} style={{ cursor: "pointer" }}>
              {project.locationLat ? project.locationLat : ""},{" "}
              {project.locationLong ? project.locationLong : ""}
            </b>
          </div>
        </div>
        <div className="project_details__livret_container">
          {project.livret ? (
            <iframe
              title="RF"
              id="pdfPreview"
              width="100%"
              height="500px"
              type="application/pdf"
              style={{ border: "none", borderRadius: "10px" }}
              src={`${process.env.REACT_APP_BASE_IMAGE_URL}/${project.livret.imageName}`}
            >
              <p>The PDF cannot be displayed.</p>
            </iframe>
          ) : (
            ""
          )}
        </div>
        {project.medias && project.medias.length > 0 ? (
          <div className="project_details__media_container paper">
            <h4 style={{ fontSize: "18px" }}>Medias</h4>
            <div className="media__lib__container">
              {project.medias.map((el) =>
                el.imageName.includes(".mp4") ? (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      background: "#dddddd",
                      borderRadius: "4px",
                      display: "grid",
                      alignItems: "center",
                    }}
                  >
                    <video
                      controls
                      width="100%"
                      height="auto"
                      key={el.imageName}
                    >
                      <source
                        src={`${process.env.REACT_APP_BASE_IMAGE_URL}/${el.imageName}`}
                        type="video/mp4"
                      />
                      Sorry, your browser doesn't support embedded videos.
                    </video>
                  </div>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      background: "#dddddd",
                      borderRadius: "4px",
                      display: "grid",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={`${process.env.REACT_APP_BASE_IMAGE_URL}/${el.imageName}`}
                      alt={el.imageName}
                      onClick={handleImageClick}
                      key={el.imageName}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        ) : (
          ""
        )}

        {project?.mediaFolderUrl && project?.mediaFolderUrl?.length > 0 ? (
          <div className="project_details__media_container paper">
            <h4 style={{ fontSize: "18px" }}>Mon projet en images</h4>
            <div
              style={{
                width: "100%",
                height: "100%",
                background: "#dddddd",
                borderRadius: "4px",
                display: "grid",
                alignItems: "center",
              }}
            >
              <iframe
                src={`${project.mediaFolderUrl}?appearance=light&layout=grid&themecolor=dark&toolbar=false&breadcrumbs=false`}
                title="Mon projet en images"
                frameborder="0"
                allowfullscreen="true"
                width="100%"
                height="620"
              ></iframe>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ProjectDetails;
