import React from "react";
import "./main.css";

const Pagination = ({ pageNum, pagesTotalNum, pageNumCallback }) => {
  const handlePrevClick = () => {
    if (pageNum > 1) {
      pageNumCallback(pageNum - 1);
    }
  };
  const handleNextClick = () => {
    if (pageNum < pagesTotalNum) {
      pageNumCallback(pageNum + 1);
    }
  };
  const handleInputChange = (e) => {
    if (e.target.value <= pagesTotalNum && e.target.value >= 1)
      pageNumCallback(e.target.value);
  };

  return (
    <div className="pagination__container box__shadow">
      <div className="btn__previous__container">
        <button
          className="btn__sm"
          title="Page précedente"
          onClick={handlePrevClick}
          disabled={pageNum === 1 ? true : false}
        >
          <span>&#8249;</span> Préc
        </button>
      </div>
      <div className="pagination__actual_total__pages">
        <input
          type="number"
          onChange={handleInputChange}
          value={pageNum}
          disabled
        />{" "}
        / {pagesTotalNum}
      </div>
      <div className="btn__next__container">
        <button
          className="btn__sm"
          title="Page suivante"
          onClick={handleNextClick}
          disabled={pageNum === pagesTotalNum ? true : false}
        >
          Suiv <span>&#8250;</span>
        </button>
      </div>
    </div>
  );
};

export default Pagination;
