import React from "react";
import "../main.css";
import { useHistory } from "react-router-dom";
import FormatDate from "../../../components/FormatDate";
// import emptyImage from "../../../assets/images/emptyImage.png";
import emptyImage from "../../../assets/images/projects-empty-image.png";

import donEffectueIcon from "../../../assets/images/projects/doneffectue.png";
import donEffectueSelectedIcon from "../../../assets/images/projects/doneffectueSelected.png";
import donEffectueActualIcon from "../../../assets/images/projects/doneffectueActual.png";

import diagnostiqueIcon from "../../../assets/images/projects/diagnostique.png";
import diagnostiqueSelectedIcon from "../../../assets/images/projects/diagnostiqueSelected.png";
import diagnostiqueActualIcon from "../../../assets/images/projects/diagnostiqueActual.png";

import affectationVillageIcon from "../../../assets/images/projects/affectationvillage.png";
import affectationVillageSelectedIcon from "../../../assets/images/projects/affectationvillageSelected.png";
import affectationVillageActualIcon from "../../../assets/images/projects/affectationvillageActual.png";

import debutTravauxIcon from "../../../assets/images/projects/debuttravaux.png";
import debutTravauxSelectedIcon from "../../../assets/images/projects/debuttravauxSelected.png";
import debutTravauxActualIcon from "../../../assets/images/projects/debuttravauxActual.png";

import constructionEnCoursIcon from "../../../assets/images/projects/constructionencours.png";
import constructionEnCoursSelectedIcon from "../../../assets/images/projects/constructionencoursSelected.png";
import constructionEnCoursActualIcon from "../../../assets/images/projects/constructionencoursActual.png";

import puitsTermineIcon from "../../../assets/images/projects/puitstermine.png";
import puitsTermineSelectedIcon from "../../../assets/images/projects/puitstermineSelected.png";
import puitsTermineActualIcon from "../../../assets/images/projects/puitstermineActual.png";

import livretreadyIcon from "../../../assets/images/projects/livretready.png";
import livretreadySelectedIcon from "../../../assets/images/projects/livretreadySelected.png";
import livretreadyActualIcon from "../../../assets/images/projects/livretreadyActual.png";

const ProjectCard = ({ project }) => {
  function ParseFloat(str, val) {
    str = str.toString();
    str = str.slice(0, str.indexOf(".") + val + 1);
    return Number(str);
  }

  let pourcentage = 0;
  const stepsActive = {
    donationSet: project.projectStep === "donationSet",
    Diagnostic: project.projectStep === "Diagnostic",
    villageAssignment: project.projectStep === "villageAssignment",
    startWorks: project.projectStep === "startWorks",
    constructionProgress: project.projectStep === "constructionProgress",
    wellFinished: project.projectStep === "wellFinished",
    livretReady: project.projectStep === "livretReady",
  };
  if (Object.values(stepsActive).includes(true)) {
    var ok = false;
    Object.keys(stepsActive).forEach((key, index) => {
      if (ok === false) {
        if (stepsActive[key] === true) {
          ok = true;
          pourcentage = ParseFloat((index + 1) * 14.2857142857, 2);
          if (pourcentage > 100) {
            pourcentage = 100;
          }
          if (project.projectStatus === "ended") {
            pourcentage = 100;
          }
        }
        stepsActive[key] = true;
      }
    });
  }

  let history = useHistory();
  const handleLink = (e) => {
    e.preventDefault();
    history.push(`/projects/${project.id}`);
  };

  return (
    <>
      <div className="project__container row">
        {/* <div className="project__options">
          <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
          <div className="project__options__list">
            <a
              href={`/projects/${project.id}`}
              onClick={handleLink}
              title="Détails"
            >
              Voir les détails
            </a>
          </div>
        </div> */}
        <div className="project__image_container col-md-2">
          <a
            href={`/projects/${project.id}`}
            onClick={handleLink}
            className="simple_link"
          >
            <img
              src={
                project.photo
                  ? process.env.REACT_APP_BASE_IMAGE_URL +
                    "/" +
                    project.photo.imageName
                  : emptyImage
              }
              alt={project.name}
              style={{ maxWidth: "100%" }}
            />
          </a>
        </div>
        <div className="col-md-10">
          <div className="row">
            <div className="project__info_title_desc col-md-7 col-12">
              <h3>
                <a
                  href={`/projects/${project.id}`}
                  onClick={handleLink}
                  className="simple_link"
                >
                  {project.name}
                </a>
                <span
                  className={
                    project.projectStatus === "ended"
                      ? "project__info__status__badge project__ended__bg"
                      : "project__info__status__badge"
                  }
                >
                  {project.projectStatus === "inprogress"
                    ? "EN COURS"
                    : project.projectStatus === "ended"
                    ? "TERMINÉ"
                    : project.projectStatus}
                </span>
              </h3>
              <p>{project.description}</p>
            </div>
            <div className="project__info_percentage col-md-5 col-12">
              <div align="right" className="project__info__amount__collected">
                {/* <span>
                  {" "}
                  <b
                    className={
                      project.projectStatus === "ended"
                        ? "project__ended__color"
                        : ""
                    }
                  >
                    {project.amountCollected ?? 0} €
                  </b>{" "}
                  Collectés
                </span> */}
                <span className="project__info__amount__collected__percentage">
                  {pourcentage} %<br />
                </span>
              </div>
              <div className="project__progress_bar">
                <div
                  className={
                    project.projectStatus === "ended"
                      ? "project__progress project__ended__bg"
                      : "project__progress"
                  }
                  style={{
                    width: pourcentage + "%",
                  }}
                ></div>
              </div>
              {/* <p className="project__info__amount__goal">
                Objectif: {project.amountGoal ?? 0} €
              </p> */}
            </div>
          </div>
          {project.projectStatus !== "ended" ? (
            <div className="project__status_line__cont">
              <div className="project__status_line col-md-12">
                <div
                  className="project__status_line_active"
                  style={
                    project.projectStep === "donationSet"
                      ? { width: "calc(" + 100 / 7 + "% - 25px)" }
                      : project.projectStep === "Diagnostic"
                      ? { width: "calc(" + (100 / 8) * 2 + "% - 25px)" }
                      : project.projectStep === "villageAssignment"
                      ? { width: "calc(" + (100 / 8) * 3 + "% - 25px)" }
                      : project.projectStep === "startWorks"
                      ? { width: "calc(" + (100 / 8) * 4 + "% - 25px)" }
                      : project.projectStep === "constructionProgress"
                      ? { width: "calc(" + (100 / 8) * 5 + "% - 25px)" }
                      : project.projectStep === "wellFinished"
                      ? { width: "calc(" + (100 / 8) * 6 + "% - 25px)" }
                      : project.projectStep === "livretReady"
                      ? { width: "100%" }
                      : { width: "0%" }
                  }
                ></div>
                <span
                  className="project__state__icon"
                  style={{ marginLeft: "calc(" + 100 / 7 + "% - 25px)" }}
                >
                  <img
                    src={
                      stepsActive.donationSet &&
                      project.projectStep === "donationSet"
                        ? donEffectueActualIcon
                        : stepsActive.donationSet
                        ? donEffectueSelectedIcon
                        : donEffectueIcon
                    }
                    alt="Don éffectué"
                    className={
                      stepsActive.donationSet &&
                      project.projectStep !== "donationSet"
                        ? "actualstep__dropShadow"
                        : ""
                    }
                  />
                  <br />
                  <b className="project__step__title">Don éffectué</b>
                </span>

                <span
                  className="project__state__icon"
                  style={{ marginLeft: "calc(" + (100 / 8) * 2 + "% - 25px)" }}
                >
                  <img
                    src={
                      stepsActive.Diagnostic &&
                      project.projectStep === "Diagnostic"
                        ? diagnostiqueActualIcon
                        : stepsActive.Diagnostic
                        ? diagnostiqueSelectedIcon
                        : diagnostiqueIcon
                    }
                    alt="Diagnostic"
                    className={
                      stepsActive.Diagnostic &&
                      project.projectStep !== "Diagnostic"
                        ? "actualstep__dropShadow"
                        : ""
                    }
                  />
                  <br />
                  <b className="project__step__title">Diagnostic</b>
                </span>

                <span
                  className="project__state__icon"
                  style={{ marginLeft: "calc(" + (100 / 8) * 3 + "% - 25px)" }}
                >
                  <img
                    src={
                      stepsActive.villageAssignment &&
                      project.projectStep === "villageAssignment"
                        ? affectationVillageActualIcon
                        : stepsActive.villageAssignment
                        ? affectationVillageSelectedIcon
                        : affectationVillageIcon
                    }
                    alt="Affectation Village"
                    className={
                      stepsActive.villageAssignment &&
                      project.projectStep !== "villageAssignment"
                        ? "actualstep__dropShadow"
                        : ""
                    }
                  />
                  <br />
                  <b className="project__step__title">Affectation Village</b>
                </span>

                <span
                  className="project__state__icon"
                  style={{ marginLeft: "calc(" + (100 / 8) * 4 + "% - 25px)" }}
                >
                  <img
                    src={
                      stepsActive.startWorks &&
                      project.projectStep === "startWorks"
                        ? debutTravauxActualIcon
                        : stepsActive.startWorks
                        ? debutTravauxSelectedIcon
                        : debutTravauxIcon
                    }
                    alt="Début Travaux"
                    className={
                      stepsActive.startWorks &&
                      project.projectStep !== "startWorks"
                        ? "actualstep__dropShadow"
                        : ""
                    }
                  />
                  <br />
                  <b className="project__step__title">Début Travaux</b>
                </span>

                <span
                  className="project__state__icon"
                  style={{ marginLeft: "calc(" + (100 / 8) * 5 + "% - 25px)" }}
                >
                  <img
                    src={
                      stepsActive.constructionProgress &&
                      project.projectStep === "constructionProgress"
                        ? constructionEnCoursActualIcon
                        : stepsActive.constructionProgress
                        ? constructionEnCoursSelectedIcon
                        : constructionEnCoursIcon
                    }
                    alt="Construction En Cours"
                    className={
                      stepsActive.constructionProgress &&
                      project.projectStep !== "constructionProgress"
                        ? "actualstep__dropShadow"
                        : ""
                    }
                  />
                  <br />
                  <b className="project__step__title">Construction En Cours</b>
                </span>

                <span
                  className="project__state__icon"
                  style={{ marginLeft: "calc(" + (100 / 8) * 6 + "% - 25px)" }}
                >
                  <img
                    src={
                      stepsActive.wellFinished &&
                      project.projectStep === "wellFinished"
                        ? puitsTermineActualIcon
                        : stepsActive.wellFinished
                        ? puitsTermineSelectedIcon
                        : puitsTermineIcon
                    }
                    alt="Puits Terminé"
                    className={
                      stepsActive.wellFinished &&
                      project.projectStep !== "wellFinished"
                        ? "actualstep__dropShadow"
                        : ""
                    }
                  />
                  <br />
                  <b className="project__step__title">Puits Terminé</b>
                </span>

                <span
                  className="project__state__icon"
                  style={{ marginLeft: "calc(" + (100 / 8) * 7 + "% - 25px)" }}
                >
                  <img
                    src={
                      stepsActive.livretReady &&
                      project.projectStep === "livretReady"
                        ? livretreadyActualIcon
                        : stepsActive.livretReady
                        ? livretreadySelectedIcon
                        : livretreadyIcon
                    }
                    alt="Livret disponible"
                    className={
                      stepsActive.livretReady &&
                      project.projectStep !== "livretReady"
                        ? "actualstep__dropShadow"
                        : ""
                    }
                  />
                  <br />
                  <b className="project__step__title">Livret disponible</b>
                </span>
              </div>
            </div>
          ) : (
            <div className="project__status_line_ended col-md-12 row">
              <div className="col-6 col-sm-6 col-md-3 project___status__ended_details_cont">
                Date d'inauguration
                <br />
                <b>
                  {project.endDate ? <FormatDate date={project.endDate} /> : ""}
                </b>
              </div>
              <div className="col-6 col-sm-6 col-md-3 project___status__ended_details_cont">
                Beneficiaires
                <br />
                <b>{project.beneficiairesNumber}</b>
              </div>
              <div className="col-6 col-sm-6 col-md-3 project___status__ended_details_cont">
                Profondeur
                <br />
                <b>{project.projectDepth}</b>
              </div>
              <div className="col-6 col-sm-6 col-md-3 project___status__ended_details_cont">
                Localisation
                <br />
                <b>
                  {project.locationLat}, {project.locationLong}
                </b>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* <div className="project__container row">
        <div className="project__image_container col-md-2">
          <img
            src={emptyImage}
            alt="project name"
            style={{ maxWidth: "100%" }}
          />
        </div>
        <div className="col-md-10">
          <div className="row">
            <div className="project__info_title_desc col-md-6 col-12">
              <h3>
                Puit Mecanise{" "}
                <span className="project__info__status__badge project__ended__bg">
                  TERMINÉ
                </span>
              </h3>
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sit
                temporibus tempora sapiente h
              </p>
            </div>
            <div className="project__info_percentage col-md-6 col-12">
              <p className="project__info__amount__collected">
                <span>
                  {" "}
                  <b className="project__ended__color">1228 €</b> Collectés
                </span>
                <span className="project__info__amount__collected__percentage">
                  91.40%
                </span>
              </p>
              <div className="project__progress_bar">
                <div
                  className="project__progress project__ended__bg"
                  style={{
                    width: "50%",
                  }}
                ></div>
              </div>
              <p className="project__info__amount__goal">Objectif: 2500 €</p>
            </div>
          </div>

          <div className="project__status_line_ended col-md-12 row">
            <div className="col-6 col-sm-6 col-md-3 project___status__ended_details_cont">
              Date d'inauguration
              <br />
              <b>02 jul 2020</b>
            </div>
            <div className="col-6 col-sm-6 col-md-3 project___status__ended_details_cont">
              Beneficiaires
              <br />
              <b>650</b>
            </div>
            <div className="col-6 col-sm-6 col-md-3 project___status__ended_details_cont">
              Profondeur
              <br />
              <b>24m</b>
            </div>
            <div className="col-6 col-sm-6 col-md-3 project___status__ended_details_cont">
              Localisation
              <br />
              <b>16.654500, -3.082583</b>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default ProjectCard;
