import "./main.css";
import LogoLife from "../../assets/images/logoLifeWhite.svg";
import ProfileIcon from "../../assets/images/drawerUserProfileIcon.svg";
import homeIcon from "../../assets/images/homeIcon.svg";
import LogoutIcon from "../../assets/images/drawerLogoutIcon.svg";
import ContactIcon from "../../assets/images/drawerContactIcon.svg";
// import CollectsIcon from "../../assets/images/drawerCollectIcon.svg";
import DonationsIcon from "../../assets/images/drawerDonationIcon.svg";
import ProjectsIcon from "../../assets/images/drawerProjectsIcon.svg";

// Redux
import { loggOut } from "../../providers/actions";
import { useDispatch } from "react-redux";

// router
import { NavLink } from "react-router-dom";
const Drawer = () => {
  const dispatch = useDispatch();

  const handleDrawerClick = () => {
    document.querySelector("#drawer").classList.add("close__drawer");
    document.querySelector("#drawer").classList.remove("open__drawer");
  };
  return (
    <div id="drawer" className="drawer__container close__drawer">
      <div
        className="drawer__overlay__mobile"
        onClick={handleDrawerClick}
      ></div>
      <div className="drawer__container__inner">
        <div className="drawer__logo__container">
          <NavLink to="/">
            <img
              src={LogoLife}
              width="100"
              className="no__dark"
              alt="Logo LIFE-ONG"
            />
          </NavLink>
        </div>

        <div className="drawer__items__container">
          <div className="drawer__items__container__inner">
            <NavLink
              to="/"
              className="drawer__item__link"
              activeClassName="drawer__item__link-active"
              exact
            >
              <div className="drawer__item">
                <div align="center">
                  <img src={homeIcon} alt="Profile" />
                </div>
                <span>Dashboard</span>
              </div>
            </NavLink>

            <NavLink
              to="/profile"
              className="drawer__item__link"
              activeClassName="drawer__item__link-active"
            >
              <div className="drawer__item">
                <div align="center">
                  <img src={ProfileIcon} alt="Profile" />
                </div>
                <span>Mon profil</span>
              </div>
            </NavLink>

            <NavLink
              to="/donations"
              className="drawer__item__link"
              activeClassName="drawer__item__link-active"
            >
              <div className="drawer__item">
                <div align="center">
                  <img src={DonationsIcon} alt="Donations" />
                </div>
                <span>Mes dons</span>
              </div>
            </NavLink>

            <NavLink
              to="/projects"
              className="drawer__item__link"
              activeClassName="drawer__item__link-active"
            >
              <div className="drawer__item">
                <div align="center">
                  <img src={ProjectsIcon} alt="Projects" />
                </div>
                <span>Mes projets</span>
              </div>
            </NavLink>

            {/* <NavLink
              to="#life"
              className="drawer__item__link"
              activeClassName="drawer__item__link-active"
            >
              <div className="drawer__item">
                <div align="center">
                  <img src={CollectsIcon} alt="collects" />
                </div>
                <span>Mes collectes</span>
              </div>
            </NavLink> */}
            <NavLink
              to="/contact"
              className="drawer__item__link"
              activeClassName="drawer__item__link-active"
            >
              <div className="drawer__item">
                <div align="center">
                  <img src={ContactIcon} alt="Projects" />
                </div>
                <span>Contacter l'association</span>
              </div>
            </NavLink>
            {/* <a
              href="https://life-ong.org/contactez-nous/"
              className="drawer__item__link"
              target="_blank"
              rel="noreferrer"
            >
              <div className="drawer__item">
                <div align="center">
                  <img src={ContactIcon} alt="Contact" />
                </div>
                <span>Contactez l'association</span>
              </div>
            </a> */}
          </div>
        </div>

        <div className="drawer__logout__container">
          <div className="drawer__logout__container__inner">
            {/* <a href="#life" className="drawer__item__link"> */}
            <div
              className="drawer__item"
              onClick={() => {
                dispatch(loggOut());
              }}
            >
              <div align="center">
                <img src={LogoutIcon} alt="LogOut" />
              </div>
              <span>Se déconnecter</span>
            </div>

            {/* </a> */}
          </div>
          <div align="center" style={{ fontSize: "13px", color: "#f36f21" }}>
            VERSION BETA
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drawer;
