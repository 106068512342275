import React from "react";

import FormatDate from "../../../components/FormatDate";

const MessageCard = ({ message, loggedInUserEmail }) => {
  return (
    <div
      align={loggedInUserEmail === message.user.email ? "right" : "left"}
      className="message__card__container"
    >
      <div className="message__card__container__inner">
        <p
          dangerouslySetInnerHTML={{
            __html: message.content.replaceAll("\n", "<br/>"),
          }}
        ></p>
        {message.attachement ? (
          <div align="left">
            <div
              className="message__card__attachement_container"
              title={message.attachement.imageName}
            >
              <i
                className="message__card__attachement__file_icon fa fa-file-o "
                aria-hidden="true"
              ></i>

              <a
                href={`${process.env.REACT_APP_BASE_IMAGE_URL}/${message.attachement.imageName}`}
                download={message.attachement.imageName}
                target="_blank"
                rel="noreferrer"
              >
                <span className="message__card__attachement__download__btn">
                  <span>Télecharger</span>
                </span>
              </a>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="message__card_info" align="right">
          <span>
            <i className="fa fa-user" style={{ color: " #e55e33" }}></i>
            {message.user.name}
          </span>
          <span>
            <i className="fa fa-clock-o " style={{ color: " #e55e33" }}></i>
            <FormatDate
              date={message.createdAt}
              format="letter"
              widthTime="true"
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default MessageCard;
