const API = async (method, url, data, type) => {
  var authorization = "";
  const donor = JSON.parse(localStorage.getItem("donor"));
  if (donor) {
    authorization = donor.token;
  }

  var headers = {};
  var boyContent = {};
  if (type === "FormData") {
    headers = {
      Authorization: "bearer " + authorization,
    };
    boyContent = data;
  } else {
    headers = {
      Authorization: "bearer " + authorization,
      "content-Type": "application/json",
    };
    boyContent = JSON.stringify(data);
  }

  try {
    const fetchapi = await fetch(url, {
      method: method,
      headers: headers,
      body: boyContent,
    });
    const fetchapiJson = await fetchapi.json();

    if (
      fetchapiJson.code === 401 &&
      fetchapiJson.message === "Expired JWT Token"
    ) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("donor");
      window.location.href = "/login";
      return false;
    } else if (
      fetchapiJson.code === 401 &&
      fetchapiJson.message === "JWT Token not found"
    ) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("donor");
      window.location.href = "/login";
    }
    return fetchapiJson;
  } catch (error) {
    window.href = "/login";
    return error;
  }
};
export default API;
