import React, { useEffect, useState } from "react";
import API from "../../../utils/API";
import { useDispatch } from "react-redux";
import { loggOut } from "../../../providers/actions";

import Modal from "../../../components/Modal";
function ChangePassConfirm() {
  const dispatch = useDispatch();
  /** MODAL */
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [modalType, setModaltype] = useState("error");
  const closeModalHandler = () => {
    setModalVisible(false);
    setModalMsg("");
  };
  /** END MODAL */
  const showAlert = (msg, type) => {
    setModalVisible(true);
    setModalMsg(msg);
    setModaltype(type);
  };

  useEffect(() => {
    const changePass = async () => {
      const data = await API(
        "GET",
        `${process.env.REACT_APP_API_BASE_URL}/confirm-update-pass`
      );
      if (data.code === 200) {
        showAlert(data.msg, "success");
        setTimeout(() => {
          dispatch(loggOut());
        }, 3000);
      }
    };
    changePass();
  }, [dispatch]);
  return (
    <section>
      <Modal
        modalMsg={modalMsg}
        modalVisible={modalVisible}
        modalClose={closeModalHandler}
        modalType={modalType}
      />
    </section>
  );
}

export default ChangePassConfirm;
