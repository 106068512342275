import { useEffect, useState } from "react";
import API from "../../../utils/API";
import CurrencyFormat from "../../../components/CurrencyFormat";
import FormatDate from "../../../components/FormatDate";
import Loading from "../../../components/Loading";

const SingleDonationContainer = ({ index, donation }) => {
  return (
    <div className="lastThreeDonations__container">
      <span className="lastThreeDonations__num">
        <span>{index + 1}</span>
      </span>
      <span className="lastThreeDonations__camp">
        <span className="lastThreeDonations__camp__date">
          <FormatDate date={donation.paymentDate} format={"letter"} />
        </span>
        <br />
        <span className="lastThreeDonations__camp__name">
          {donation.campaignParent.name}
        </span>
      </span>
      {/* <span className="lastThreeDonations__date"></span> */}
      <span className="lastThreeDonations__amount">
        {<CurrencyFormat amount={donation.amount} />}
      </span>
    </div>
  );
};

const ThreeLastDonations = () => {
  const [donations, setDonations] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const response = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/donations`
        );
        if (response.code === 200) {
          setDonations(response.data.slice(0, 3));
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    getData();
  }, []);

  return loading ? (
    <Loading loading={loading} />
  ) : donations.length > 0 ? (
    donations.map((donation, index) => (
      <SingleDonationContainer key={index} donation={donation} index={index} />
    ))
  ) : (
    <p align="center">Vous n'avez aucun don</p>
  );
};

export default ThreeLastDonations;
