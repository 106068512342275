import React, { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import Pagination from "../../components/Pagination";
import ProjectCard from "./ProjectCard";
import API from "../../utils/API";

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const reloadCallback = () => {
    setReload(!reload);
  };
  const [pagesTotalNum, setPagesTotalNum] = useState(1);
  const [pageNum, setPageNum] = useState(1);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const data = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/my-projects?pageNum=${pageNum}`
        );
        setProjects(data.data);
        setPagesTotalNum(Math.ceil(data.total / data.pageSize));
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
    return () => {
      setProjects([]);
    };
  }, [pageNum]);

  const pageNumCallback = (num) => {
    setPageNum(parseInt(num));
  };
  return (
    <section>
      <h2 className="page__title">MES PROJETS</h2>
      <div>
        {loading ? (
          <div
            className="box__shadow loading__container"
            style={{
              height: "210px",
            }}
          >
            <Loading />
          </div>
        ) : projects && projects.length > 0 ? (
          projects.map((p, index) => (
            <ProjectCard
              key={index}
              project={p}
              reloadCallback={reloadCallback}
            />
          ))
        ) : (
          <div className="no__project__container">
            <p align="center">Vous n'avez aucun Projet</p>
          </div>
        )}
      </div>
      {projects && pagesTotalNum > 1 ? (
        <Pagination
          pageNum={pageNum}
          pagesTotalNum={pagesTotalNum}
          pageNumCallback={pageNumCallback}
        />
      ) : (
        ""
      )}
    </section>
  );
};

export default Projects;
