import React, { useEffect, useState } from "react";

import API from "../../../utils/API";

import DonateurLife from "../../../assets/images/badges/donateur_life.png";
import AmbassadorBadge from "../../../assets/images/badges/ambassadeur.png";
import AmbassadorBadgeDisabled from "../../../assets/images/badges/ambassadeur-disabled.png";
import WaterSponsor from "../../../assets/images/badges/water_sponsor.png";
import WaterSponsorDisabled from "../../../assets/images/badges/water_sponsor-disabled.png";
import Donateur1e1repas from "../../../assets/images/badges/donateur_1e1repas.png";
import Donateur1e1repasDisabled from "../../../assets/images/badges/donateur_1e1repas-disabled.png";
import DonateurZakat from "../../../assets/images/badges/donateur_zakat.png";
import DonateurZakatDisabled from "../../../assets/images/badges/donateur_zakat-disabled.png";
import DonateurSapousse from "../../../assets/images/badges/donateur_sapousse.png";
import DonateurSapousseDisabled from "../../../assets/images/badges/donateur_sapousse-disabled.png";

import CollecteTerminee from "../../../assets/images/badges/collecte_terminee.png";
import CollecteTermineeDisabed from "../../../assets/images/badges/collecte_terminee-disabled.png";
import ProjetTermine from "../../../assets/images/badges/projet_termine.png";
import ProjetTermineDisabled from "../../../assets/images/badges/projet_termine-disabled.png";

import { useSwipeable } from "react-swipeable";

import Loading from "../../../components/Loading";

const DonorBadges = () => {
  const [badges, setBadges] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const response = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/badges`
        );
        if (response.code === 200) {
          setBadges(response.data);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    getData();
  }, []);

  const handlers = useSwipeable({
    onSwipedLeft: () => setSlideActive(!slideActive),
    onSwipedRight: () => setSlideActive(!slideActive),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const [slideActive, setSlideActive] = useState(true);
  const handleCarouselChangeSlide1 = (e) => {
    setSlideActive(!slideActive);
    document
      .querySelectorAll(".custom__carousel__pagination__item i")
      .forEach((el, i) => {
        el.style.opacity = 1;
      });
    e.target.style.opacity = 0.3;
  };
  const handleCarouselChangeSlide2 = (e) => {
    setSlideActive(!slideActive);
    document
      .querySelectorAll(".custom__carousel__pagination__item i")
      .forEach((el, i) => {
        el.style.opacity = 1;
      });
    e.target.style.opacity = 0.3;
  };

  return loading ? (
    <Loading loading={loading} />
  ) : (
    <div>
      <div {...handlers} className="custom__carousel">
        {slideActive ? (
          <div className="custom__carousel__item">
            <div className="badge__container">
              <div className="badge__item badge__atributed">
                <img
                  src={DonateurLife}
                  alt="Donateur LIFE"
                  title="Donateur LIFE"
                />
                <div className="share__overlay">
                  <p>Partager sur: </p>
                  <div>
                    <a
                      href={`http://www.facebook.com/sharer.php?u=https://donor.life-ong.net${DonateurLife}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                    <a
                      href={`https://twitter.com/intent/tweet?text=Donateur LIFE&hashtags=LIFEONG`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-twitter"></i>
                    </a>
                    <a
                      href={`http://pinterest.com/pin/create/button/?url=https://donor.life-ong.net${DonateurLife}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-pinterest"></i>
                    </a>
                  </div>
                </div>
              </div>
              {badges.includes("ambassadeur") ? (
                <div className="badge__item badge__atributed">
                  <img
                    src={AmbassadorBadge}
                    alt="Ambassadeur"
                    title="Ambassadeur"
                  />
                  <div className="share__overlay">
                    <p>Partager sur: </p>
                    <div>
                      <a
                        href={`http://www.facebook.com/sharer.php?u=https://donor.life-ong.net${AmbassadorBadge}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                      <a
                        href={`https://twitter.com/intent/tweet?text=Ambassadeur LIFE&hashtags=LIFEONG`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-twitter"></i>
                      </a>
                      <a
                        href={`http://pinterest.com/pin/create/button/?url=https://donor.life-ong.net${AmbassadorBadge}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-pinterest"></i>
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="badge__item">
                  <img
                    src={AmbassadorBadgeDisabled}
                    alt="Ambassadeur désactivé"
                    title="Badge pas encore attribué"
                  />
                </div>
              )}
              {badges.includes("water sponsor") ? (
                <div className="badge__item badge__atributed">
                  <img
                    className=""
                    src={WaterSponsor}
                    alt="Water sponsor"
                    title="Water sponsor"
                  />
                  <div className="share__overlay">
                    <p>Partager sur: </p>
                    <div>
                      <a
                        href={`http://www.facebook.com/sharer.php?u=https://donor.life-ong.net${WaterSponsor}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                      <a
                        href={`https://twitter.com/intent/tweet?text=Water sponsor&hashtags=LIFEONG`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-twitter"></i>
                      </a>
                      <a
                        href={`http://pinterest.com/pin/create/button/?url=https://donor.life-ong.net${WaterSponsor}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-pinterest"></i>
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="badge__item">
                  <img
                    src={WaterSponsorDisabled}
                    alt="Water sponsor désactivé"
                    title="badge pas encore attribué"
                  />
                </div>
              )}
              {badges.includes("donateur 1e1repas") ? (
                <div className="badge__item badge__atributed">
                  <img
                    src={Donateur1e1repas}
                    alt="Donateur 1e1repas"
                    title="Donateur 1e1repas"
                  />
                  <div className="share__overlay">
                    <p>Partager sur: </p>
                    <div>
                      <a
                        href={`http://www.facebook.com/sharer.php?u=https://donor.life-ong.net${Donateur1e1repas}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                      <a
                        href={`https://twitter.com/intent/tweet?text=Donateur 1e1repas&hashtags=LIFEONG`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-twitter"></i>
                      </a>
                      <a
                        href={`http://pinterest.com/pin/create/button/?url=https://donor.life-ong.net${Donateur1e1repas}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-pinterest"></i>
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="badge__item">
                  <img
                    src={Donateur1e1repasDisabled}
                    alt="Donateur 1e1repas désactivé"
                    title="Badge pas encore attribué"
                  />
                </div>
              )}
              {badges.includes("donateur zakat") ? (
                <div className="badge__item badge__atributed">
                  <img
                    src={DonateurZakat}
                    alt="Donateur zakat"
                    title="Donateur zakat"
                  />
                  <div className="share__overlay">
                    <p>Partager sur: </p>
                    <div>
                      <a
                        href={`http://www.facebook.com/sharer.php?u=https://donor.life-ong.net${DonateurZakat}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                      <a
                        href={`https://twitter.com/intent/tweet?text=Donateur zakat&hashtags=LIFEONG`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-twitter"></i>
                      </a>
                      <a
                        href={`http://pinterest.com/pin/create/button/?url=https://donor.life-ong.net${DonateurZakat}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-pinterest"></i>
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="badge__item">
                  <img
                    src={DonateurZakatDisabled}
                    alt="Donateur zakat désactivé"
                    title="Badge pas encore attribué"
                  />
                </div>
              )}
              {badges.includes("donateur sapousse") ? (
                <div className="badge__item badge__atributed">
                  <img
                    src={DonateurSapousse}
                    alt="Donateur sapousse"
                    title="Donateur sapousse"
                  />
                  <div className="share__overlay">
                    <p>Partager sur: </p>
                    <div>
                      <a
                        href={`http://www.facebook.com/sharer.php?u=https://donor.life-ong.net${DonateurSapousse}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                      <a
                        href={`https://twitter.com/intent/tweet?text=Donateur sapousse&hashtags=LIFEONG`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-twitter"></i>
                      </a>
                      <a
                        href={`http://pinterest.com/pin/create/button/?url=https://donor.life-ong.net${DonateurSapousse}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-pinterest"></i>
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="badge__item">
                  <img
                    src={DonateurSapousseDisabled}
                    alt="Donateur sapousse désactivé"
                    title="Badge pas encore attribué"
                  />
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="custom__carousel__item">
            <div className="badge__container">
              {badges.includes("projet terminé") ? (
                <div className="badge__item badge__atributed">
                  <img
                    src={ProjetTermine}
                    alt="Projet terminé"
                    title="Projet terminé"
                  />
                  <div className="share__overlay">
                    <p>Partager sur: </p>
                    <div>
                      <a
                        href={`http://www.facebook.com/sharer.php?u=https://donor.life-ong.net${ProjetTermine}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                      <a
                        href={`https://twitter.com/intent/tweet?text=Projet terminé LIFE&hashtags=LIFEONG`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-twitter"></i>
                      </a>
                      <a
                        href={`http://pinterest.com/pin/create/button/?url=https://donor.life-ong.net${ProjetTermine}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-pinterest"></i>
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="badge__item">
                  <img
                    src={ProjetTermineDisabled}
                    alt="Projet terminé désactivé"
                    title="Badge pas encore attribué"
                  />
                </div>
              )}
              {badges.includes("collect terminé") ? (
                <div className="badge__item badge__atributed">
                  <img
                    src={CollecteTerminee}
                    alt="Collecte terminée"
                    title="Collecte terminée"
                  />
                  <div className="share__overlay">
                    <p>Partager sur: </p>
                    <div>
                      <a
                        href={`http://www.facebook.com/sharer.php?u=https://donor.life-ong.net${CollecteTerminee}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                      <a
                        href={`https://twitter.com/intent/tweet?text=Collecte terminée&hashtags=LIFEONG`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-twitter"></i>
                      </a>
                      <a
                        href={`http://pinterest.com/pin/create/button/?url=https://donor.life-ong.net${CollecteTerminee}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-pinterest"></i>
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="badge__item">
                  <img
                    src={CollecteTermineeDisabed}
                    alt="Collecte terminée désactivé"
                    title="Badge pas encore attribué"
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div align="center" className="custom__carousel__pagination">
        <span
          className="custom__carousel__pagination__item"
          onClick={handleCarouselChangeSlide1}
        >
          <i
            className="fa fa-circle"
            aria-hidden="true"
            style={{ opacity: "0.3" }}
          ></i>
        </span>
        <span
          className="custom__carousel__pagination__item"
          onClick={handleCarouselChangeSlide2}
        >
          <i className="fa fa-circle" aria-hidden="true"></i>
        </span>
      </div>
    </div>
  );
};

export default DonorBadges;
