import React, { useState } from "react";
import Modal from "../../components/Modal";
import API from "../../utils/API";
import Loading from "../../components/Loading";
import { useSelector } from "react-redux";
const connectedDonor = (state) => state.isLogged.donorInfo;
const Contact = () => {
  const donorInfo = useSelector(connectedDonor);

  const [loading, setLoading] = useState(false);
  /** MODAL */
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [modalType, setModaltype] = useState("error");
  const closeModalHandler = () => {
    setModalVisible(false);
    setModalMsg("");
    setModaltype("error");
  };
  /** END MODAL */

  const handleformSubmit = async (e) => {
    e.preventDefault();

    const form = document.querySelector("#contactForm");

    const firstLastName = e.target.firstlastname.value;
    const email = e.target.email.value;
    // const file = e.target.attachement.files
    //   ? e.target.attachement.files[0]
    //   : null;
    //const attachment = file ? URL.createObjectURL(file) : null;
    const object = e.target.object.value;
    const msg = e.target.message.value;

    if (!firstLastName || !email || !object || !msg) {
      setModalVisible(true);
      setModalMsg("Veuillez compléter les champs manquants");
      setModaltype("error");
      return false;
    }

    const FD = new FormData(e.target);

    try {
      setLoading(true);
      const res = await API(
        "POST",
        `${process.env.REACT_APP_API_BASE_URL}/contact`,
        FD,
        "FormData"
      );
      if (res.code === 200) {
        setModalVisible(true);
        setModalMsg("Votre Message est envoyé");
        setModaltype("success");
        form.reset();
      } else {
        setModalVisible(true);
        setModalMsg(res.msg);
        setModaltype("error");
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      return false;
    }
  };

  return (
    <section>
      <Modal
        modalMsg={modalMsg}
        modalVisible={modalVisible}
        modalClose={closeModalHandler}
        modalType={modalType}
      />
      <h2 className="page__title">Contacter l'association</h2>
      <div className="paper">
        <div className="forms__container">
          {loading ? (
            <div
              className="box__shadow"
              style={{
                position: "absolute",
                height: "484px",
                borderRadius: "10px",
                marginBottom: "20px",
                backgroundColor: "rgba(0,0,0,0.7)",
                width: "calc(100% - 40px)",
              }}
            >
              <Loading />
            </div>
          ) : (
            <form
              onSubmit={handleformSubmit}
              autoComplete="off"
              id="contactForm"
            >
              <div className="col__2">
                <div className="form__group">
                  <label htmlFor="firstlastname">Nom et Prénom</label>
                  <input
                    required
                    type="text"
                    className="form__control"
                    id="firstlastname"
                    value={
                      donorInfo
                        ? donorInfo.firstName + " " + donorInfo.lastName
                        : ""
                    }
                    name="firstlastname"
                    autoComplete="off"
                    onChange={(e) => e.target.value}
                  />
                </div>

                <div className="form__group">
                  <label htmlFor="email">Email</label>
                  <input
                    required
                    type="email"
                    className="form__control"
                    id="email"
                    value={donorInfo ? donorInfo.email : ""}
                    name="email"
                    autoComplete="off"
                    onChange={(e) => e.target.value}
                  />
                </div>
              </div>
              <div className="col__2">
                <div className="form__group">
                  <label htmlFor="object">Objet</label>
                  <input
                    required
                    type="text"
                    className="form__control"
                    id="object"
                    name="object"
                    autoComplete="off"
                  />
                </div>

                <div className="form__group">
                  <label htmlFor="attachement">Pièce jointe</label>
                  <input
                    type="file"
                    className="form__control"
                    id="attachement"
                    name="attachement"
                  />
                </div>
              </div>

              <div className="form__group">
                <label htmlFor="message">Message</label>
                <textarea
                  className="form__control"
                  name="message"
                  id="message"
                  cols="20"
                  rows="8"
                ></textarea>
              </div>

              <div align="center">
                <button type="submit" className="btn__sm">
                  Envoyer
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </section>
  );
};

export default Contact;
