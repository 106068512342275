import "./main.css";
const Table = ({ data, emptyMSG }) => {
  return (
    <div className="table-responsive-xl">
      <table className="table table-striped table-borderless table-bg-white table-text-dark box__shadow">
        <thead className="text-capitalize table__head">
          <tr>
            {data.length ? (
              Object.keys(data[0]).map((col, index) => {
                return index === 0 ? (
                  <th key={index} scope="col" className="radius__topleft">
                    {col === "RF" ? "" : col}
                  </th>
                ) : index === Object.keys(data[0]).length - 1 ? (
                  <th key={index} scope="col" className="radius__topright ">
                    {col === "RF" ? "" : col}
                  </th>
                ) : (
                  <th key={index} scope="col">
                    {col === "RF" ? "" : col}
                  </th>
                );
              })
            ) : (
              <td className="radius__topleft radius__topright">{""}</td>
            )}
          </tr>
        </thead>
        <tbody>
          {data.length ? (
            data.map((element, index) => {
              return (
                <tr key={index}>
                  {Object.keys(element).map((col, index1) => {
                    return <td key={index1}>{element[col]}</td>;
                  })}
                </tr>
              );
            })
          ) : (
            <tr>
              <td>{emptyMSG ? emptyMSG : "Vous n'avez aucun don"}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
