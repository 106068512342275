import React, { useState, useEffect } from "react";
import Loading from "../../../components/Loading";
import ProjectCard from "../../Projects/ProjectCard";
import API from "../../../utils/API";

const LastThreeProjects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const response = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/last-projects`
        );
        if (response.code === 200) {
          setProjects(response.data.slice(0, 3));
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    getData();

    return () => {
      setProjects([]);
    };
  }, []);

  return loading ? (
    <Loading loading={loading} />
  ) : projects.length > 0 ? (
    <div>
      {projects.map((p, index) => (
        <ProjectCard key={index} project={p} />
      ))}
    </div>
  ) : (
    <p align="center">Vous n'avez aucun projet en cours</p>
  );
};

export default LastThreeProjects;
