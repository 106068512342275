const now = new Date().getTime();
const expirationTime = localStorage.getItem("expiration");
if (expirationTime < now) {
  // localStorage.clear();
  localStorage.removeItem("expiration");
  localStorage.removeItem("donor");
}
const donor = JSON.parse(localStorage.getItem("donor"));
const initialState = donor
  ? { isLoggedIn: true, donor }
  : { isLoggedIn: false, donor: null };

// const initialState = {
//   isLoggedIn: true,
//   donor: {
//     id: 11,
//     email: "developpeur@life-ong.org",
//     first_name: "Maroine",
//   },
// };

const LoggedReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "IS_SIGNED_IN":
      return { ...state, donorInfo: payload };

    case "SIGN_IN":
      localStorage.setItem("donor", JSON.stringify(payload));
      const hours = 40;
      const expirationTime = hours * 60 * 60 * 1000;
      localStorage.setItem("expiration", new Date().getTime() + expirationTime);
      return {
        ...state,
        isLoggedIn: true,
        donor: payload,
      };

    case "SIGN_OUT":
      localStorage.removeItem("expiration");
      localStorage.removeItem("donor");
      return {
        ...state,
        isLoggedIn: false,
        donor: null,
      };

    default:
      return state;
  }
};

export default LoggedReducer;
