import React from "react";

const styles = {
  container: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: "rgba(0,0,0,0.4)",
    zIndex: 9999,
  },
  containerInner: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: "90vw",
    height: "90vh",
    maxWidth: "90%",
    maxheight: "90%",
    margin: "auto",
    borderRadius: "4px",
  },
  closeModal: {
    margin: "auto 10px",
    fontSize: "1.5em",
    fontWeight: "200 !important",
    zIndex: "999",
    padding: "10px",
    cursor: "pointer",
    color: "var(--button-primary-text-color)",
    position: "absolute",
    right: 0,
  },
  closePopupLayer: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
};
const Popup = ({ children, popupCallback }) => {
  const handleClose = () => {
    popupCallback();
  };
  return (
    <div style={styles.container}>
      <div style={styles.closePopupLayer} onClick={handleClose}></div>
      <span style={styles.closeModal} onClick={handleClose}>
        &#10005;
      </span>
      <div style={styles.containerInner}>{children}</div>
    </div>
  );
};

export default Popup;
