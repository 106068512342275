import React from "react";
import "./main.css";
import { Link } from "react-router-dom";

import API from "../../utils/API";
import FormatDate from "../FormatDate";

const NotifPanel = ({ notifs, callback }) => {
  const handleNotifClick = async (id) => {
    try {
      const response = await API(
        "POST",
        `${process.env.REACT_APP_API_BASE_URL}/notifs/${id}`
      );
      if (response.code === 200) callback();
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="notif__container">
      {notifs.length > 0 ? (
        notifs.map((notif) => (
          <Link
            key={notif.id}
            to={`/${notif.type}/${notif.detailsId ? notif.detailsId : ""}`}
            className="simple_link"
            onClick={() => handleNotifClick(notif.id)}
          >
            <div className={notif.opened ? "opened" : "unopened"}>
              {notif.message} -{" "}
              <FormatDate
                date={notif.createdAt}
                format={"letter"}
                widthTime={true}
              />
            </div>
          </Link>
        ))
      ) : (
        <div align="center">Aucune notification</div>
      )}
    </div>
  );
};

export default NotifPanel;
