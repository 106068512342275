import React, { useState } from "react";

import Logo from "../../assets/images/Logo-life-min.png";
// Router
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import Modal from "../../components/Modal";

import "./main.css";
import API from "../../utils/API";
const ForgotPassword = () => {
  const history = useHistory();
  /** MODAL */
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [modalType, setModaltype] = useState("error");
  const closeModalHandler = () => {
    setModalVisible(false);
    setModalMsg("");
    setModaltype("error");
  };
  /** END MODAL */

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const email = e.target.user_email.value;

    try {
      const response = await API(
        "POST",
        `${process.env.REACT_APP_API_BASE_PUBLIC_URL}/donor/reset-pass`,
        {
          email,
        }
      );

      if (response.code === 200) {
        setModalVisible(true);
        setModalMsg(response.msg);
        setModaltype("success");

        setTimeout(() => {
          history.push("/login");
        }, 5000);
      } else {
        setModalVisible(true);
        setModalMsg(response.msg);
        setModaltype("error");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="forgotpass__container">
      <Modal
        modalMsg={modalMsg}
        modalVisible={modalVisible}
        modalClose={closeModalHandler}
        modalType={modalType}
      />
      <div className="form__container">
        <div align="center" style={{ marginBottom: "50px" }}>
          <img width="100px" src={Logo} alt="LIFE-ONG LOGO" />
        </div>

        <div className="form__container__inner">
          <form onSubmit={handleFormSubmit}>
            <h1 align="center" className="fp__page__title">
              Mot de passe oublié
            </h1>
            <div className="form__group">
              <label htmlFor="user_email">Email</label>
              <input className="form__control" type="email" id="user_email" />
            </div>
            <div align="center">
              <button type="submit" className="btn__sm">
                Envoyer
              </button>
            </div>
          </form>
        </div>

        <div className="margin-10-15">
          <Link to="/login" className="simple_link font-bold font-14">
            Se connecter?
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
