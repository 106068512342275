import { useState } from "react";

import "./main.css";
import Modal from "../../components/Modal";


import Loading from "../../components/Loading";

import API from "../../utils/API";

const ChangePasswordFirstLogin = (props) => {
  const [loading, setLoading] = useState(false);




  const [password, setPassword] = useState("");
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const handlePasswordConfirmChange = (e) => {
    setPasswordConfirm(e.target.value);
  };



  /** MODAL */
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [modalType, setModaltype] = useState("error");
  const closeModalHandler = () => {
    setModalVisible(false);
    setModalMsg("");
  };
  /** END MODAL */



  const showAlert = (msg, type) => {
    setModalVisible(true);
    setModalMsg(msg);
    setModaltype(type);
  };

  const handleformPassSubmit = async (e) => {
    e.preventDefault();

    if (password.length < 6) {
      showAlert(
        "Mot de passe doit etre composé d'au moins 6 caractères",
        "error"
      );
      return false;
    }
    if (password !== passwordConfirm) {
      showAlert("Les mots de passe ne se correspondent pas", "error");
      return false;
    }

    try {
      setLoading(true);
      const data = await API(
        "POST",
        `${process.env.REACT_APP_API_BASE_URL}/config-password`,
        { password: password, id: props.donorId }
      );

      if (data.code === 200) {
        setLoading(false);
        showAlert(data.msg, "success");
   
       props.closeChangePwModalHandler();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);

      showAlert(
        "Une erreur est survenu, SVP réessayer plus tard ou contactez l'administrateur du site",
        "error"
      );
    }
  };
  



  return (
    <section>
      <Modal
        modalMsg={modalMsg}
        modalVisible={modalVisible}
        modalClose={closeModalHandler}
        modalType={modalType}
      />


      {loading ? (
        <Loading />
      ) : (
        <div className="paper">
          <div className="profile__nav">
        
            <div
              className={
                "profile__nav__item__pw profile__nav__item-active__pw"
                  
              }
             
            >
              Définir un mot de passe
            </div>
          </div>
          <div className="forms__container">
            <form
              action="#"
              style={{ display: "block" }}
              noValidate
              onSubmit={handleformPassSubmit}
              autoComplete="off"
            >
              <div className="col__2">
                <div className="form__group">
                  <label htmlFor="password">Mot de passe</label>
                  <input
                    required
                    type="password"
                    className="form__control"
                    id="password"
                    name="password"
                    value={password}
                    onChange={handlePasswordChange}
                    autoComplete="off"
                  />
                </div>
                <div className="form__group">
                  <label htmlFor="password_confirm">
                    Confirmez le Mot de passe
                  </label>
                  <input
                    required
                    type="password"
                    className="form__control"
                    id="password_confirm"
                    name="password_confirm"
                    value={passwordConfirm}
                    onChange={handlePasswordConfirmChange}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div align="center">
                <button type="submit" className="btn__md">
                  Mettre à jour
                </button>
              </div>
            </form>
           
          </div>
        </div>
      )}
  
    </section>
  );
};

export default ChangePasswordFirstLogin;
