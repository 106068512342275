import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import API from "../../../utils/API";
import Modal from "../../../components/Modal";
import Loading from "../../../components/Loading";
import Badge from "../../../components/Badge";
import MessageCard from "../MessageCard";
import { useSelector } from "react-redux";

const TicketDetails = () => {
  const loggedInUserEmail = useSelector((state) =>
    state.isLogged
      ? state.isLogged.donorInfo
        ? state.isLogged.donorInfo.email
        : ""
      : ""
  );
  const { id } = useParams();

  const [ticket, setTicket] = useState({ id: "", status: "", object: "" });
  const [messages, setmessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  /** MODAL */
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [modalType, setModaltype] = useState("error");
  const closeModalHandler = () => {
    setModalVisible(false);
    setModalMsg("");
    setModaltype("error");
  };
  /** END MODAL */

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const response = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/ticket/${id}`
        );
        // console.log(response.data);
        if (response.code === 200) {
          setTicket({
            id: response.data.id,
            status: response.data.status,
            object: response.data.object,
          });
          setmessages(response.data.message);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    getData();
    return () => {
      setTicket({ id: "", status: "", object: "" });
      setmessages([]);
    };
  }, [id, reload]);

  const handleReplySubmit = async (e) => {
    e.preventDefault();

    const content = e.target.content.value;
    if (!content) {
      setModalVisible(true);
      setModalMsg("Veuillez compléter les champs manquants");
      setModaltype("error");
      return false;
    }

    const FD = new FormData(e.target);

    try {
      setLoading(true);
      const res = await API(
        "POST",
        `${process.env.REACT_APP_API_BASE_URL}/ticket-donor-reply/${id}`,
        FD,
        "FormData"
      );
      if (res.code === 200) {
        setModalVisible(true);
        setModalMsg("Message envoyé");
        setModaltype("success");
        e.target.reset();
        setReload(true);
      } else {
        setModalVisible(true);
        setModalMsg(res.msg);
        setModaltype("error");
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      return false;
    }
  };
  return (
    <div>
      <Modal
        modalMsg={modalMsg}
        modalVisible={modalVisible}
        modalClose={closeModalHandler}
        modalType={modalType}
      />
      <div className="ticket__messages__object_status">
        <span className="ticket__messages__object">
          Objet: <span>{ticket.object}</span>
        </span>
        <span>
          <Badge
            string={ticket.status}
            type={ticket.status === "Terminé" ? "success" : ""}
          />
        </span>
      </div>
      {ticket.status !== "Terminé" ? (
        <div className="form__reply__container">
          <form className="msg_send" onSubmit={handleReplySubmit}>
            <div className="form__group">
              <label htmlFor="attachement">Pièce jointe (optionnel)</label>
              <input
                type="file"
                className="form__control"
                id="attachement"
                name="attachement"
              />
            </div>
            <div className="form__group">
              <label htmlFor="content">Message</label>
              <textarea
                className="form__control"
                name="content"
                id="content"
                rows="4"
              ></textarea>
            </div>

            <div align="right">
              <button className="btn__sm" type="submit">
                <i className="fa fa-paper-plane-o" aria-hidden="true"></i>{" "}
                Répondre
              </button>
            </div>
          </form>
        </div>
      ) : (
        ""
      )}
      {loading ? (
        <Loading />
      ) : messages && messages.length > 0 ? (
        <div className="ticket__messages__container">
          {/* {ticket.lastDonorMsg ? <div>{ticket.lastDonorMsg}</div> : ""}
          {ticket.content ? (
            <div
              dangerouslySetInnerHTML={{
                __html: JSON.parse(JSON.stringify(ticket.content)),
              }}
            ></div>
          ) : (
            ""
          )} */}
          {messages.map((message) => (
            <div key={message.id}>
              <MessageCard
                message={message}
                loggedInUserEmail={loggedInUserEmail}
              />
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default TicketDetails;
