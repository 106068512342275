import { useState, useEffect } from "react";

import "./main.css";
import Logo from "../../assets/images/Logo-life-min.png";

import API from "../../utils/API";

import Modal from "../../components/Modal";

// redux
import { loggIn } from "../../providers/actions";
import { useDispatch } from "react-redux";

// Router
import { Link } from "react-router-dom";

const Login = ({ history }) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  /** MODAL */
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [modalType, setModaltype] = useState("error");
  const closeModalHandler = () => {
    setModalVisible(false);
    setModalMsg("");
    setModaltype("error");
  };
  /** END MODAL */

  const isLoggedIn = localStorage.getItem("loggedIn") === "true";
  useEffect(() => {
    if (isLoggedIn) {
      setTimeout(() => {
        window.location.href = "/";
      }, 100);
    }
  }, [isLoggedIn]);

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    //login here
    // localStorage.setItem("loggedIn", true);
    // setModalVisible(true);
    // setModalMsg("you are logged in successfully");
    // setModaltype("success");

    if (!email || !password || password.length < 4) {
      setModalVisible(true);
      setModalMsg("Verifiez vos informations");
      setModaltype("error");
      return false;
    }

    // const FD = new FormData();
    // FD.append("email", email);
    // FD.append("password", password);

    try {
      const response = await API(
        "POST",
        `${process.env.REACT_APP_API_BASE_URL}/login_check`,
        {
          username: email,
          password: password,
        }
      );

      if (response.token) {
        setModalVisible(true);
        setModalMsg("Connecté");
        setModaltype("success");

        dispatch(loggIn(response));
        setTimeout(() => {
          history.push("/");
        }, 2000);
      } else {
        setModalVisible(true);
        setModalMsg("Email ou mot de passe non valide");
        setModaltype("error");
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <div className="login__page__container">
      <Modal
        modalMsg={modalMsg}
        modalVisible={modalVisible}
        modalClose={closeModalHandler}
        modalType={modalType}
      />
      <div className="login__page__container__inner">
        <div align="center" style={{ marginBottom: "50px" }}>
          <img width="100px" src={Logo} alt="LIFE-ONG LOGO" />
        </div>
        <div className="login__page__form__container">
          <form onSubmit={handleSubmitForm}>
            <h1 align="center">Se connecter</h1>
            <div className="form__group">
              <label htmlFor="user_email">Email</label>
              <input
                className="form__control"
                type="email"
                id="user_email"
                value={email}
                onChange={handleEmailChange}
              />
            </div>
            <div className="form__group">
              <label htmlFor="user_password">Mot de passe</label>
              <input
                className="form__control"
                type="password"
                value={password}
                id="user_password"
                onChange={handlePasswordChange}
              />
            </div>
            <div align="center">
              <button type="submit" className="btn__sm">
                Se connecter
              </button>
            </div>
          </form>
        </div>
        <div className="margin-10-15">
          <Link to="/forgot-password" className="simple_link font-bold font-14">
            Mot de passe oublié?
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
