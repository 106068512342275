// import API from "../../utils/API";

export const loggIn = (donor) => {
  return {
    type: "SIGN_IN",
    payload: donor,
  };
};

export const loadDonor = (donor) => {
  // console.log(donor);
  //const data = loadUser();
  return {
    type: "IS_SIGNED_IN",
    payload: donor,
  };
};

export const loggOut = () => {
  return {
    type: "SIGN_OUT",
  };
};

// const loadUser = async () => {
//   const dataJson = await API(
//     "GET",
//     `${process.env.REACT_APP_API_BASE_URL}/donor/logged-in`
//   );
//   const data = await dataJson.json();
//   return data;
// };
