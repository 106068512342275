import React from "react";
import "./App.css";
import Routes from "./routes/Routes";
// import Checkout from "./views/Chekout";
import CacheBuster from "react-cache-buster";
import { version } from "../package.json";

import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory();

const App = () => {
  const isProduction = process.env.NODE_ENV === "production";
  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<p>LOADING...</p>} //If not pass, nothing appears at the time of new version check.
    >
      <Router history={browserHistory}>
        <Routes />
      </Router>
    </CacheBuster>
  );
};

export default App;
