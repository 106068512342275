import { useState, useEffect } from "react";
import "./main.css";

import userImage from "../../assets/images/user-profile.png";
import notifBell from "../../assets/images/notif-bell.png";
import notifBellNew from "../../assets/images/notif-bell-new.png";
import dot from "../../assets/images/Oval.png";

// Redux
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { loggOut } from "../../providers/actions";
// import searchIcon from "../../assets/images/searchIcon.svg";
import { Link } from "react-router-dom";
import NotifPanel from "../NotifPanel";

import API from "../../utils/API";

const Header = () => {
  const dispatch = useDispatch();

  const profileImage = useSelector((state) =>
    state.isLogged.donorInfo && state.isLogged.donorInfo.photo
      ? `${process.env.REACT_APP_BASE_IMAGE_URL}/` +
        state.isLogged.donorInfo.photo.imageName
      : ""
  );

  const [drawerState, setDrawerState] = useState(true);
  const openCloseDrawer = () => {
    setDrawerState(!drawerState);
    const windowWidth = window.innerWidth;
    if (windowWidth < 992) {
      document.querySelector("#drawer").classList.remove("close__drawer");
      document.querySelector("#drawer").classList.add("open__drawer");
    } else {
      if (!drawerState) {
        document.querySelector("#drawer").classList.remove("close__drawer");
        document.querySelector("#drawer").classList.add("open__drawer");
      } else {
        document.querySelector("#drawer").classList.add("close__drawer");
        document.querySelector("#drawer").classList.remove("open__drawer");
      }
    }
  };

  useEffect(() => {
    // let dark = localStorage.getItem("DarkMode");
    // if (dark === "true") {
    //   setDark();
    // } else {
    //   setLight();
    // }
    const windowWidth = window.innerWidth;
    if (windowWidth < 992) {
      setDrawerState(false);
      // console.log("false");
    } else {
      setDrawerState(true);
      // console.log("true");
      document.querySelector("#drawer").classList.remove("close__drawer");
      document.querySelector("#drawer").classList.add("open__drawer");
    }
  }, []);
  const [notifs, setnotifs] = useState([]);
  const [reload, setrelaod] = useState(false);
  const [newNotifs, setnewNotifs] = useState(false);
  useEffect(() => {
    const getNotifs = async () => {
      try {
        const response = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/notifs`
        );
        if (response.code === 200) {
          setnotifs(response.data);

          response.data.forEach((element) => {
            if (!element.opened) {
              setnewNotifs(true);
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    getNotifs();

    setTimeout(() => {
      setrelaod(!reload);
    }, 10 * 60000);
    return () => {
      setnotifs([]);
    };
  }, [reload]);

  const notifCallback = () => {
    setrelaod(!reload);
  };

  // const setDark = () => {
  //   document.querySelector("#darkmodeSwitch").checked = true;
  //   localStorage.setItem("DarkMode", "true");

  //   document.querySelector("html").setAttribute("theme", "dark-mode");
  // };

  // const setLight = () => {
  //   localStorage.setItem("DarkMode", "false");
  //   document.querySelector("#darkmodeSwitch").checked = false;

  //   document.querySelector("html").setAttribute("theme", "");
  // };
  // const handleThemeChange = (el) => {
  //   if (el.target.checked) {
  //     setDark();
  //   } else {
  //     setLight();
  //   }
  // };

  return (
    <nav>
      <div className="first__element__nav__container">
        <button className="drawer__openclose__btn" onClick={openCloseDrawer}>
          <i className="fa fa-bars" aria-hidden="true"></i>
        </button>
        {/* <div className="search__container">
          <img className="icon__search" src={searchIcon} alt="search" />
          <input
            className="search__input"
            type="text"
            placeholder="Recherche"
          />
        </div> */}
      </div>

      <div className="second__element__nav__container">
        <div className="dropdown__notif">
          <span className="notification__icon_cont">
            <img
              src={newNotifs ? notifBellNew : notifBell}
              alt="notification icon"
            />
          </span>
          <div className="dropdown__notif__content">
            <NotifPanel notifs={notifs} callback={notifCallback} />
          </div>
        </div>
        <div className="dot__separation">
          <img src={dot} alt="dot" />
        </div>
        <div className="user__icon dropdown">
          <img
            src={profileImage ? profileImage : userImage}
            alt="user profile"
            className="do__not_invert"
          />
          <span>
            <i className="fa fa-chevron-down" aria-hidden="true"></i>
          </span>
          <div className="dropdown__content">
            {/* <div align="center">
              <label className="donor__switch">
                <input
                  id="darkmodeSwitch"
                  type="checkbox"
                  onChange={handleThemeChange}
                />
                <span className="donor__dl__slider donor__dl__round"></span>
              </label>
            </div> */}

            <div>
              <Link to="/profile">Mon profil</Link>
            </div>

            <div>
              <span
                onClick={() => {
                  dispatch(loggOut());
                }}
              >
                Se déconnecter
              </span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
