import React, { useEffect, useState } from "react";
import "./main.css";
import Table from "../../components/Table";
import CurrencyFormat from "../../components/CurrencyFormat";
import FormatDate from "../../components/FormatDate";
import API from "../../utils/API";

import repasLogo from "../../assets/images/Logo_1e1repas_noir.png";
import wsLogo from "../../assets/images/ws-logo.png";
import sapousseLogo from "../../assets/images/Sapousse-logo.png";
import Pagination from "../../components/Pagination";

import Loading from "../../components/Loading";

import { useSelector } from "react-redux";

const Donations = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagesTotalNum, setPagesTotalNum] = useState(1);
  const [pageNum, setPageNum] = useState(1);
  const [donationsYear, setDonationsYear] = useState("all");
  const [totalByYear, setTotalByYear] = useState(0);
  const pageNumCallback = (num) => {
    setPageNum(parseInt(num));
  };

  const donorInfo = useSelector((state) => {
    if (state.isLogged) {
      return state.isLogged.donorInfo;
    }
  });

  const loggedInDonorId = donorInfo ? donorInfo.id : null;


  console.log("donorInfo", donorInfo);

  const donorAddress = donorInfo ? donorInfo.address : null;
  const donorCity = donorInfo ? donorInfo.city : null;
  const donorCountry = donorInfo ? donorInfo.country : null;
  const donorZipCode = donorInfo ? donorInfo.zipCode : null;

  const [years, setYears] = useState([]);

  const LogoRepas = ({ title }) => (
    <img
      align="center"
      src={repasLogo}
      alt="1e1repas logo"
      className="campaign__image"
      title={title}
    />
  );

  const LogoWs = ({ title }) => (
    <img
      align="center"
      src={wsLogo}
      alt="Water Sponsor logo"
      className="campaign__image"
      title={title}
    />
  );

  const LogoSapousse = ({ title }) => (
    <img
      align="center"
      src={sapousseLogo}
      alt="Sapousse logo"
      className="campaign__image"
      title={title}
    />
  );

  const capitalize = (s) => {
    return s[0].toUpperCase() + s.slice(1);
  };

  useEffect(() => {
    const date = new Date();
    var year = date.getFullYear();
    var yearsToSet = [];

    while (year >= 2020) {
      yearsToSet.push(year);
      year = year - 1;
    }

    setYears(yearsToSet);

    const getData = async () => {
      try {
        setLoading(true);
        const response = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/donations?pageNum=${pageNum}&year=${donationsYear}`
        );
        if (response.code === 200) {
          let dataToReturn = [];
          response.data.map((donation) => {
            let amount = donation.amount;

            return dataToReturn.push({
              Date: (
                <b>
                  <FormatDate date={donation.paymentDate} format={"letter"} />
                </b>
              ),
              // Campagne: decodeURIComponent(
              //   escape(donation.campaignParent.name)
              // ),
              Campagne:
                donation.campaign.name === "1 E 1 Repas" ? (
                  <LogoRepas title={donation.campaignParent.name} />
                ) : donation.campaignParent.name === "Sapousse" ? (
                  <LogoSapousse title={donation.campaignParent.name} />
                ) : donation.campaignParent.name === "Fond eau" ||
                  donation.campaignParent.name === "Puits mécanisé" ||
                  donation.campaignParent.name === "Puits traditionnel" ||
                  donation.campaignParent.name === "Collecte en cours puits" ||
                  donation.campaignParent.name === "Puits forage solaire" ? (
                  <LogoWs title={donation.campaign.name} />
                ) : (
                  donation.campaignParent.name
                ),
              Régularité: donation.regularity
                ? capitalize(donation.regularity)
                : "Inconnu",
              Montant: (
                <span style={{ color: "#f36f21", fontWeight: "bold" }}>
                  <CurrencyFormat amount={amount} />
                </span>
              ),
              // RF: returnRf(
              //   `https://reporting.life-ong.org/api/tax-receipts/RecuFiscal.php?num_order=${donation.id}`
              // ),
            });
          });
          setTotalByYear(response.totalByYear);
          setData(dataToReturn);
          setPagesTotalNum(Math.ceil(response.total / response.pageSize));
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    getData();

    return () => {
      setYears([]);
      setTotalByYear(0);
      setData([]);
      setPagesTotalNum(1);
    };
  }, [pageNum, donationsYear]);

  const handleYearChange = (e) => {
    setDonationsYear(e.target.value);
    setPageNum(1);
  };

  const handleDownloadRfClick = async () => {
    try {
      window.open(
        `${process.env.REACT_APP_API_BASE_PUBLIC_URL}/recu-fiscal/${donationsYear}/${loggedInDonorId}`
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="donations__section">
      <h2 className="page__title">Mes Dons</h2>
      <div className="rf__info__download">
        <span>LIFE délivre des reçus fiscaux en fin d'année et pas au don</span>
        <br />
        <span>
          Sélectionnez l'année pour télécharger votre reçu fiscal annuel
        </span>
      </div>
      <div className="donations__filter">
        <div>
          <label
            htmlFor="yearToDisplay"
            style={{ fontWeight: "bold", marginBottom: "0" }}
          >
            Année{" "}
          </label>
          <select
            name="yearToDisplay"
            id="yearToDisplay"
            onChange={handleYearChange}
            value={donationsYear}
          >
            <option value="all">Tous</option>
            {years &&
              years.map((year, index) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
          </select>
        </div>
        <div align="right">
          {donationsYear !== "all" ? (
            donationsYear !== `${new Date().getFullYear()}` &&
            totalByYear ? (
              <div style={{display: "inline-block"}}>
                {!donorCountry || !donorAddress || !donorCity || !donorZipCode ? (
                    <div className="rf__info" style={{display: "inline-block", fontSize: "13px"}}>
                      <i className="fa fa-info-circle" aria-hidden="true"></i>
                      Merci de remplir votre adresse pour télécharger le Réçu fiscal
                    </div>
                  ) : (
                    ""
                  )
                }
              <button
                className="btn__xs rf__annuel__btn"
                title="Télécharger"
                onClick={handleDownloadRfClick}
                disabled={!donorCountry || !donorAddress || !donorCity || !donorZipCode }
              >
                <i className="fa fa-download" aria-hidden="true"></i> Reçu
                fiscal
              </button>
              </div>
            ) : totalByYear ? (
              <button
                className="btn__xs rf__annuel__btn"
                title="Télécharger"
                onClick={handleDownloadRfClick}
              >
                <i className="fa fa-download" aria-hidden="true"></i>{" "}
                Attestation de don
              </button>
            ) : (
              ""
            )
          ) : (
            ""
          )}

          <span className="donations__filter__total_label">Total: </span>
          <span className="donations__filter__total_number">
            <CurrencyFormat amount={totalByYear} />
          </span>
        </div>
      </div>
      {loading ? (
        <div
          className="box__shadow loading__container"
          style={{
            height: "650px",
          }}
        >
          <Loading />
        </div>
      ) : data && data.length > 0 ? (
        <Table data={data} />
      ) : (
        <div className="no__donations__container">
          <p align="center">Vous n'avez aucun don</p>
        </div>
      )}

      {data && pagesTotalNum > 1 ? (
        <Pagination
          pageNum={pageNum}
          pagesTotalNum={pagesTotalNum}
          pageNumCallback={pageNumCallback}
        />
      ) : (
        ""
      )}
      
      <div className="rf__info">
        <i className="fa fa-info-circle" aria-hidden="true"></i>
        Chaque don donne droit à une réduction d’impôt égale à 75% du montant du
        don, dans la limite de 1000€. Au-delà, la réduction d’impôt passe à 66%,
        dans la limite de 20% de vos revenus imposables. (
        <a
          href="https://www.impots.gouv.fr/portail/particulier/questions/jai-fait-des-dons-une-association-que-puis-je-deduire"
          target="_blank"
          rel="noreferrer"
        >
          plus d'information ici
        </a>
        )
      </div>
    </section>
  );
};

export default Donations;
