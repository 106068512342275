import React, { useEffect, useState } from "react";
import "./main.css";
import { Link } from "react-router-dom";
import wsShape from "../../assets/images/ws-shape.png";
import ThreeLastDonations from "./LastThreeDonations";

import API from "../../utils/API";
import LastThreeProjects from "./LastThreeProjects";
import Loading from "../../components/Loading";
import DonorBadges from "./DonorBadges";

const Dashboard = () => {
  const [loadingNextEvent, setLoadingNextEvent] = useState(false);
  const [loadingCollectOfTheMonth, setLoadingCollectOfTheMonth] =
    useState(false);
  const [nextEvent, setNextEvent] = useState({
    name: "",
    campaign: "",
    bannerUrl: "",
  });
  const [collectMoth, setCollectMoth] = useState({
    title: "",
    description: "",
    link: "",
    bannerUrl: "",
    collected: 0,
    goal: 0,
  });

  const getData = async () => {
    try {
      setLoadingNextEvent(true);
      const response = await API(
        "GET",
        `${process.env.REACT_APP_API_BASE_URL}/next-events`
      );

      if (response.code === 200) {
        const res = response.data;
        if (res) {
          setNextEvent({
            name: res.title,
            campaign: res.campaign,
            bannerUrl: `${process.env.REACT_APP_BASE_IMAGE_URL}/${res.banner.imageName}`,
            link: res.link,
            colorHexa: res.colorHexa,
            slogan: res.slogan,
          });
          setLoadingNextEvent(false);
        }
      }
    } catch (error) {
      console.error(error);
      setLoadingNextEvent(false);
    }

    try {
      setLoadingCollectOfTheMonth(true);
      const response = await API(
        "GET",
        `${process.env.REACT_APP_API_BASE_URL}/collects-month`
      );
      if (response.code === 200) {
        const res = response.data;
        if (res) {
          setLoadingCollectOfTheMonth(false);
          setCollectMoth({
            title: res.name,
            description: res.description,
            link: res.link,
            bannerUrl: `${process.env.REACT_APP_BASE_IMAGE_URL}/${res.banner.imageName}`,
            collected: res.collected,
            goal: res.goal,
          });
        }
      }
    } catch (error) {
      setLoadingCollectOfTheMonth(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <section>
        <h2 className="page__title">Prochain évènement</h2>
        <div className="dash__section__inner next__event__container box__shadow">
          {loadingNextEvent ? (
            <Loading />
          ) : nextEvent.bannerUrl ? (
            <img
              className="next__event__img "
              src={nextEvent.bannerUrl}
              alt={nextEvent.name}
            />
          ) : (
            ""
          )}
          <div className="next__event__info do__not_invert">
            <h3 className="next__event__eltitle">{nextEvent.name}</h3>
            <p className="next__event__elSlogan">{nextEvent.slogan}</p>
            <a
              className="next__event__btn"
              href={nextEvent.link}
              target="_blank"
              rel="noreferrer"
            >
              {nextEvent.campaign}{" "}
              <span
                className="btn__angle__right"
                style={{ color: `${nextEvent.colorHexa}` }}
              >
                &#8250;
              </span>
            </a>
            {/* <p className="next__event__elCamp">{nextEvent.campaign}</p> */}
          </div>
        </div>
      </section>
      <section>
        <h2 className="page__title">Collecte du mois</h2>
        <div className="dash__section__inner two__col">
          {loadingCollectOfTheMonth ? (
            <div className="dash__section__inner__left collect__month__container box__shadow">
              <Loading />
            </div>
          ) : (
            <div
              className="dash__section__inner__left collect__month__container do__not_invert box__shadow"
              style={
                collectMoth.bannerUrl
                  ? { backgroundImage: `url('${collectMoth.bannerUrl}')` }
                  : { background: "white" }
              }
            >
              <div className="collect__month__info ">
                <h3 className="collect__month__title">{collectMoth.title}</h3>
                <p className="collect__month__description">
                  {collectMoth.description}
                </p>
              </div>
              <div className="collect__month__btn_container">
                <div className="collect__month__progress">
                  <p style={{ display: "inline-block", color: "white" }}>
                    <b>{collectMoth.collected}</b> € collectés
                  </p>
                  <p
                    style={{
                      display: "inline-block",
                      float: "right",
                      color: "white",
                    }}
                  >
                    Objectif <b>{collectMoth.goal} €</b>
                  </p>
                  <div className="collect_progress__bar">
                    <div
                      className="collect__progress"
                      style={{
                        width:
                          (collectMoth.collected * 100) / collectMoth.goal +
                          "%",
                      }}
                    ></div>
                  </div>
                </div>
                <a
                  className="collect__month__btn"
                  href={collectMoth.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  Je soutiens
                </a>
              </div>
            </div>
          )}

          <div
            className="dash__section__inner__right dash__section__inner__right__ws do__not_invert box__shadow"
            style={{ minHeight: "230px" }}
          >
            <div
              className="dash__section__inner__right__wsShape"
              style={{ backgroundImage: "url('" + wsShape + "')" }}
            >
              <div className="dash__section__ws__container ">
                <div className="dash__section__ws__text__container ">
                  <h3 className="collect__ws__txt">
                    OFFREZ DE L'EAU À CEUX QUI EN ONT BESOIN. JE DEVIENS
                    WATER-SPONSOR
                  </h3>
                </div>
              </div>
              <div
                className="collect__month__btn_container collect__ws__btn_container"
                style={{ left: "40px", display: "inline-block" }}
              >
                <a
                  className=" collect__ws__btn"
                  href={"https://life-ong.org/water_sponsor/"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Je deviens water sponsor
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="dash__section__inner two__col">
          <div className="dash__section__inner__left ">
            {/* <h2 className="page__title">Mes collectes en cours</h2>
            <div className="dash__section__inner__left__inner box__shadow"></div> */}
            <div>
              <h2 className="page__title">Mes Dons</h2>
              <Link to="/donations" className="btn__voir_plus">
                Voir plus <span className="btn__voir_plus__icon">&#62;</span>
              </Link>
            </div>
            <div
              className="dash__section__inner__right__inner box__shadow"
              style={{
                paddingLeft: "0",
                paddingRight: "0",
                // marginBottom: "40px",
              }}
            >
              <ThreeLastDonations />
            </div>
          </div>

          <div className="dash__section__inner__right">
            <h2 className="page__title">Mes Badges</h2>
            <div
              className="dash__section__inner__right__inner box__shadow"
              style={{ minHeight: "280px", padding: "20px 5px 40px" }}
            >
              <DonorBadges />
            </div>
            {/* <div>
              <h2 className="page__title">Mes Badges</h2>
            </div>
            <div className="dash__section__inner__right__inner box__shadow"></div> */}
          </div>
        </div>
      </section>

      <section>
        <h2 className="page__title">Mes projets en cours</h2>
        <Link to="/projects" className="btn__voir_plus">
          Voir plus <span className="btn__voir_plus__icon">&#62;</span>
        </Link>
        <div className="dash__section__inner one__col">
          <LastThreeProjects />
        </div>
      </section>
    </>
  );
};

export default Dashboard;
