import React, { useEffect, useState } from "react";
import "../assets/font-awesome/css/font-awesome.min.css";
import "../assets/bootstrap/css/bootstrap.min.css";
import "../App.css";

import DonateurLife from "../assets/images/badges/donateur_life.png";
import DonateurLifeDisabled from "../assets/images/badges/donateur_life-disabled.png";
// components
import Header from "../components/Header";
import Drawer from "../components/Drawer";
import API from "../utils/API";
import Loading from "../components/Loading";
import Modal from "../components/Modal";
import ChangePasswordFirstLogin from "../views/Profile/changePasswordFirstLogin";

const DefaultLayout = (props) => {
  const { children } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const [changePwModalVisible, setChangePwModalVisible] = useState(false);
  const [firstBadge, setFirstBadge] = useState(DonateurLifeDisabled);
  const closeModalHandler = () => {
    setModalVisible(false);
  };
  const closeChangePwModalHandler = (e) => {

    setChangePwModalVisible(false);
    showAlert("Mot de passe définit avec succés!", "success");
  };
  const showAlert = (msg, type) => {
    setAlertModalVisible(true);
    setAlertModalMsg(msg);
    setAlertModaltype(type);
  };
  const [loading, setLoading] = useState(false);
  const [donorId, setDonorId] = useState("");
  const [isVerified, setIsVerified] = useState();
  

  const [alertModalVisible, setAlertModalVisible] = useState(false);
  const [alertModalMsg, setAlertModalMsg] = useState("");
  const [alertModalType, setAlertModaltype] = useState("error");
  const closeAlertModalHandler = () => {
    setAlertModalVisible(false);
    setAlertModalMsg("");
  };

  useEffect(() => {
    // 1 get item from localstorage
    const firstConnection = localStorage.getItem("popupFirstTime");
    // if item dont exist display popup with welcome MSG
    if (firstConnection !== "true") {
      setModalVisible(true);

      localStorage.setItem("popupFirstTime", "true");

      setTimeout(() => {
        setFirstBadge(DonateurLife);
      }, 4000);
    }
    // else exist = true continue
  }, []);

  const style = {
    modalOverlay: {
      display: modalVisible ? "block" : "none",
      position: "fixed",
      zIndex: "999",
      background: "rgba(0,0,0,0.7)",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    modalContainer: {
      background: "#fefefe",
      height: "250px",
      bottom: 0,
      top: 0,
    },
  };


  const stylePw = {
    modalOverlay: {
      display: changePwModalVisible ? "block" : "none",
      position: "fixed",
      zIndex: "999",
      background: "rgba(0,0,0,0.7)",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    modalContainer: {
      
      height: "fit-content",
      bottom: 0,
      top: 0,
    },
    
  };

  const getDonorInfo = async () => {
    try {
      setLoading(true);
      const data = await API(
        "GET",
        `${process.env.REACT_APP_API_BASE_URL}/donor/logged-in`
      );
      if (data.code === 200) {
        const donorr = data.data;
        setDonorId(donorr.id);
        setIsVerified(donorr.isVerified);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    setLoading(false);
  };

useEffect(() => {
  

  getDonorInfo();
  return () => {

    setDonorId("");
   
    
  };
}, [isVerified]);

useEffect(() => {
  if (isVerified === false) {
setChangePwModalVisible(true);
  }
}, [isVerified]);


  return (
    <div className="App">
         <Modal
        modalMsg={alertModalMsg}
        modalVisible={alertModalVisible}
        modalClose={closeAlertModalHandler}
        modalType={alertModalType}
      />
  
    
      {/* BADGE START */}
            <div className="modal__container__overlay" style={style.modalOverlay}>
              <div className="modal__container" style={style.modalContainer}>
                <button className="btn__close" onClick={closeModalHandler}>
                  &times;
                </button>
                <div align="center" className="modal__container__inner">
                  <p align="center">
                    Bienvenue sur votre espace donateur <b>LIFE</b> ! <br />À cette
                    occasion voici votre premier badge <br />
                    <img
                      src={firstBadge}
                      alt="DonateurLife"
                      title="Donateur Life"
                      style={{ width: "100px" }}
                    />
                  </p>
                  <button className="btn__sm" onClick={closeModalHandler}>
                    C'est parti !
                  </button>
                </div>
              </div>
            </div>
            {/* BADGE END */}
       
            {/* PW START */}
            <div className="modal__container__overlay" style={stylePw.modalOverlay}>
              <div className="modal__container__pw" style={stylePw.modalContainer}>
                <ChangePasswordFirstLogin isVerified={isVerified} donorId={donorId} closeChangePwModalHandler={closeChangePwModalHandler}/>
              </div>
            </div>
            {/* PW END */}
            <Drawer />
            <div className="main__page__container">
              <Header />
              <main>{children}</main>
            </div>
          </div>
  
   

   
  );
};

export default DefaultLayout;
