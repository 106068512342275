import React from "react";

const Badge = ({ string, type }) => {
  const bgColor =
    type === "success"
      ? "#18a218"
      : type === "danger"
      ? "#ee2c2c"
      : type === "warning"
      ? "#f7c918"
      : "#9f9b9b";
  return (
    <span
      style={{
        background: bgColor,
        color: "white",
        borderRadius: "25px",
        fontSize: "13px",
        fontWeight: "bold",
        padding: "2px 5px",
      }}
    >
      {string}
    </span>
  );
};

export default Badge;
