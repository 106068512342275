import { useEffect, useState } from "react";

import "./main.css";
import Modal from "../../components/Modal";

import profileImageEmpty from "../../assets/images/profilePhotoEmpty.svg";
import ImportIcon from "../../assets/images/import.svg";

import Loading from "../../components/Loading";

import API from "../../utils/API";

const Profile = () => {
  const [loading, setLoading] = useState(false);

  const [lastName, setLastName] = useState("");
  const [donorId, setDonorId] = useState("");
  const handleLNChange = (e) => {
    setLastName(e.target.value);
  };
  const [firstName, setFirstName] = useState("");
  const handleFNChange = (e) => {
    setFirstName(e.target.value);
  };
  const [email, setEmail] = useState("");
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const [password, setPassword] = useState("");
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const handlePasswordConfirmChange = (e) => {
    setPasswordConfirm(e.target.value);
  };
  const [phoneNum, setPhoneNum] = useState("");
  const handlePhoneNumChange = (e) => {
    setPhoneNum(e.target.value);
  };
  const [company, setCompany] = useState("");
  const handleCompanyChange = (e) => {
    setCompany(e.target.value);
  };
  const [address, setAddress] = useState("");
  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };
  const [city, setCity] = useState("");
  const handleCityChange = (e) => {
    setCity(e.target.value);
  };
  const [country, setCountry] = useState("");
  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };
  const [postalCode, setPostalCode] = useState("");
  const handlePostalCode = (e) => {
    setPostalCode(e.target.value);
  };

  const [disableForm, setDisableForm] = useState(true);

  const [donorPhoto, setDonorPhoto] = useState(profileImageEmpty);
  const handleFileChange = async (el) => {
    if (el.target.files[0]) {
      const blobURL = URL.createObjectURL(el.target.files[0]);
      setDonorPhoto(blobURL);
      // document
      //   .querySelector("#profile_imageid")
      //   .classList.remove("profile_image__empty");
    } else {
      setDonorPhoto(profileImageEmpty);
    }
  };

  /** MODAL */
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [modalType, setModaltype] = useState("error");
  const closeModalHandler = () => {
    setModalVisible(false);
    setModalMsg("");
  };
  /** END MODAL */

  useEffect(() => {
    const getDonorInfo = async () => {
      try {
        setLoading(true);
        const data = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/donor/logged-in`
        );
        if (data.code === 200) {
          const donorr = data.data;
          setDonorId(donorr.id);
          if (donorr.email) setEmail(donorr.email);
          if (donorr.donor.firstName) setFirstName(donorr.donor.firstName);
          if (donorr.donor.lastName) setLastName(donorr.donor.lastName);
          if (donorr.donor.phoneNumber) setPhoneNum(donorr.donor.phoneNumber);
          if (donorr.donor.company) setCompany(donorr.donor.company);
          if (donorr.donor.address) setAddress(donorr.donor.address);
          if (donorr.donor.city) setCity(donorr.donor.city);
          if (donorr.donor.country) setCountry(donorr.donor.country);
          if (donorr.donor.zipCode) setPostalCode(donorr.donor.zipCode);
          setDisableForm(donorr.donor.infoUpdated);
          // setDisableForm(true);
          if (donorr.donor.photo) {
            setDonorPhoto(
              process.env.REACT_APP_BASE_IMAGE_URL +
                "/" +
                donorr.donor.photo.imageName
            );
            // document
            //   .querySelector("#profile_imageid")
            //   .classList.remove("profile_image__empty");
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    getDonorInfo();
    return () => {
      setDonorPhoto(profileImageEmpty);
      setDonorId("");
      setEmail("");
      setFirstName("");
      setLastName("");
      setPhoneNum("");
      setCompany("");
      setAddress("");
      setCity("");
      setCountry("");
      setPostalCode("");
      setDisableForm(true);
    };
  }, []);

  const showAlert = (msg, type) => {
    setModalVisible(true);
    setModalMsg(msg);
    setModaltype(type);
  };

  const handleformPassSubmit = async (e) => {
    e.preventDefault();

    if (password.length < 6) {
      showAlert(
        "Mot de passe doit etre composé d'au moins 6 caractères",
        "error"
      );
      return false;
    }
    if (password !== passwordConfirm) {
      showAlert("Les mots de passe ne se correspondent pas", "error");
      return false;
    }

    try {
      setLoading(true);
      const data = await API(
        "POST",
        `${process.env.REACT_APP_API_BASE_URL}/update-pass/${donorId}`,
        { password: password }
      );

      if (data.code === 200) {
        setLoading(false);
        showAlert(data.msg, "success");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);

      showAlert(
        "Une erreur est survenu, SVP réessayer plus tard ou contactez l'administrateur du site",
        "error"
      );
    }
  };
  const handleformInfoSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      showAlert("L'email est requis", "error");
      return false;
    }

    let FD = new FormData(e.target);

    try {
      setLoading(true);
      const data = await API(
        "POST",
        `${process.env.REACT_APP_API_BASE_URL}/donor-info/${donorId}`,
        FD,
        "FormData"
      );

      if (data.code === 200) {
        setLoading(false);
        showAlert(data.msg, "success");
        window.location.reload();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);

      showAlert(
        "Une erreur est survenu, SVP réessayer plus tard ou contactez l'administrateur du site",
        "error"
      );
    }
  };

  const [formPassState, setFormPassState] = useState(false);
  const [formInfoState, setFormInfoState] = useState(true);
  const [linkInfoActive, setLinkInfoActive] = useState(true);
  const [linkPassActive, setLinkPassActive] = useState(false);
  return (
    <section>
      <Modal
        modalMsg={modalMsg}
        modalVisible={modalVisible}
        modalClose={closeModalHandler}
        modalType={modalType}
      />
      <h2 className="page__title">Paramètres du compte</h2>

      {loading ? (
        <Loading />
      ) : (
        <div className="paper">
          <div className="profile__nav">
            <div
              className={
                linkInfoActive
                  ? "profile__nav__item profile__nav__item-active"
                  : "profile__nav__item"
              }
              onClick={() => {
                setFormInfoState(true);
                setFormPassState(false);
                setLinkPassActive(false);
                setLinkInfoActive(true);
              }}
            >
              Mes informations
            </div>
            <div
              className={
                linkPassActive
                  ? "profile__nav__item profile__nav__item-active"
                  : "profile__nav__item "
              }
              onClick={() => {
                setFormInfoState(false);
                setFormPassState(true);
                setLinkInfoActive(false);
                setLinkPassActive(true);
              }}
            >
              Changement mot de passe
            </div>
          </div>
          <div className="forms__container">
            <form
              action="#"
              style={{ display: formPassState ? "block" : "none" }}
              noValidate
              onSubmit={handleformPassSubmit}
              autoComplete="off"
            >
              <div className="col__2">
                <div className="form__group">
                  <label htmlFor="password">Mot de passe</label>
                  <input
                    required
                    type="password"
                    className="form__control"
                    id="password"
                    name="password"
                    value={password}
                    onChange={handlePasswordChange}
                    autoComplete="off"
                  />
                </div>
                <div className="form__group">
                  <label htmlFor="password_confirm">
                    Confirmez le Mot de passe
                  </label>
                  <input
                    required
                    type="password"
                    className="form__control"
                    id="password_confirm"
                    name="password_confirm"
                    value={passwordConfirm}
                    onChange={handlePasswordConfirmChange}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div align="center">
                <button type="submit" className="btn__md">
                  Mettre à jour
                </button>
              </div>
            </form>
            <form
              action="#"
              style={{ display: formInfoState ? "block" : "none" }}
              noValidate
              onSubmit={handleformInfoSubmit}
              autoComplete="off"
            >
              <div className="profile__imageinfo__container">
                <div className="form__group profile__imageinfo__container__inner">
                  <label htmlFor="profile_image">
                    <img
                      src={donorPhoto}
                      alt="profile"
                      className={
                        donorPhoto
                          ? "profile_image"
                          : "profile_image profile_image__empty "
                      }
                      id="profile_imageid"
                    />
                    <span className="upload__image">
                      <img src={ImportIcon} alt="upload" />
                    </span>
                  </label>
                  <input
                    type="file"
                    id="profile_image"
                    name="profile_image"
                    hidden
                    onChange={handleFileChange}
                  />
                </div>
                <div>
                  <div className="col__3">
                    <div className="form__group">
                      <label htmlFor="last_name">Nom</label>
                      <input
                        required
                        type="text"
                        className="form__control"
                        id="last_name"
                        name="lastName"
                        value={lastName}
                        onChange={handleLNChange}
                        disabled={disableForm}
                      />
                    </div>

                    <div className="form__group">
                      <label htmlFor="first_name">Prénom</label>
                      <input
                        required
                        type="text"
                        className="form__control"
                        id="first_name"
                        name="firstName"
                        value={firstName}
                        onChange={handleFNChange}
                        disabled={disableForm}
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="email">Email</label>
                      <input
                        required
                        type="text"
                        className="form__control"
                        id="email"
                        name="email"
                        value={email}
                        onChange={handleEmailChange}
                        autoComplete="off"
                        disabled={true}
                      />
                    </div>
                  </div>

                  <div className="form__group">
                    <label htmlFor="phone_number">Numéro de téléphone</label>
                    <input
                      required
                      type="tel"
                      className="form__control"
                      id="phone_number"
                      name="phoneNumber"
                      value={phoneNum}
                      onChange={handlePhoneNumChange}
                    />
                  </div>
                </div>
              </div>
              <div className="form__group">
                <label htmlFor="company">Entreprise</label>
                <input
                  type="text"
                  className="form__control"
                  id="company"
                  name="company"
                  value={company}
                  onChange={handleCompanyChange}
                />
              </div>
              <div className="form__group">
                <label htmlFor="address">Adresse</label>
                <input
                  type="text"
                  className="form__control"
                  id="address"
                  name="address"
                  value={address}
                  onChange={handleAddressChange}
                />
              </div>
              <div className="col__3">
                <div className="form__group">
                  <label htmlFor="city">Ville</label>
                  <input
                    type="text"
                    className="form__control"
                    id="city"
                    name="city"
                    value={city}
                    onChange={handleCityChange}
                  />
                </div>
                <div className="form__group">
                  <label htmlFor="country">Pays</label>
                  <input
                    type="text"
                    className="form__control"
                    id="country"
                    name="country"
                    value={country}
                    onChange={handleCountryChange}
                  />
                </div>
                <div className="form__group">
                  <label htmlFor="postal_code">Code postal</label>
                  <input
                    type="text"
                    className="form__control"
                    id="postal_code"
                    name="zipCode"
                    value={postalCode}
                    onChange={handlePostalCode}
                  />
                </div>
              </div>

              <div align="center">
                <button type="submit" className="btn__md">
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {/* <div className="profile__info">
        <i className="fa fa-info-circle" aria-hidden="true"></i>
        Nom et prénom sont modifiables qu'une seul fois.
      </div> */}
    </section>
  );
};

export default Profile;
