import React from "react";

const Loading = () => {
  return (
    <div
      className="text-center"
      style={{
        minHeight: "160px",
        height: "100%",
        margin: "auto",
        display: "flex",
      }}
    >
      <div style={{ margin: "auto", width: "48px" }}>
        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default Loading;
