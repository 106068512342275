import "./main.css";
const Modal = ({
  modalMsg,
  modalVisible,
  modalClose,
  modalType,
  timerToClose = true,
}) => {
  const alerticon =
    modalType === "error" ? (
      <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
    ) : modalType === "success" ? (
      <i className="fa fa-check-circle" aria-hidden="true"></i>
    ) : (
      <i className="fa fa-info-circle" aria-hidden="true"></i>
    );

  const styles = {
    modalContainer: {
      display: modalVisible ? "block" : "none",
      background:
        modalType === "success"
          ? "#d4edda"
          : modalType === "error"
          ? "#f8d7da"
          : "inherit",
      borderColor:
        modalType === "success"
          ? "#c3e6cb"
          : modalType === "error"
          ? "#f5c6cb"
          : "inherit",
    },
    textS: {
      color:
        modalType === "success"
          ? "#155724"
          : modalType === "error"
          ? "#721c24"
          : "inherit",
      margin: "0px 10px 0px 0px",
    },
  };

  if (timerToClose)
    setTimeout(() => {
      modalClose();
    }, 7000);

  return (
    <div className="modal__container" style={styles.modalContainer}>
      <button className="btn__close" onClick={modalClose}>
        &times;
      </button>
      <div className="modal__container__inner">
        <p style={styles.textS} align="center">
          {alerticon}
          {modalMsg}
        </p>
      </div>
    </div>
  );
};

export default Modal;
